import React, { Component } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dropzone from "components/FileUploader/Dropzone.jsx";
import Progress from "components/FileUploader/Progress.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
// @material-ui/icons
import { Check, Close, AddAlert } from "@material-ui/icons";
//colours
import { primaryColor, dangerColor } from "assets/jss/material-dashboard-react.jsx"
//classes
import WbSession from "classes/Session.jsx";

let styles = {
    upload: {
        display: "flex",
        flexDirection: "column",
        flex: "1",
        alignItems: "flex-start",
        textAlign: "left",
        overflow: "hidden",
    },
    content: {
        display: "flex",
        flexDirection: "row",
        paddingTop: "16px",
        boxSizing: "border-box",
        width: "100%",
    },
    files: {
        marginLeft: "32px",
        alignItems: "flex-start",
        justifyItems: "flex-start",
        flex: "1",
        overflowY: "auto",
    },
    actions: {
        display: "flex",
        flex: "1",
        width: "100%",
        alignItems: "flex-end",
        flexDirection: "column",
        marginTop: "32px",
    },
    title: {
        marginBottom: "32px",
        color: "#555",
    },
    button: {
        fontSize: "14px",
        display: "inline-block",
        height: "36px",
        minWidth: "88px",
        padding: "6px 16px",
        lineHeight: "1.42857143",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "middle",
        MsTouchAction: "manipulation",
        touchAction: "manipulation",
        cursor: "pointer",
        WebkitUserSelect: "none",
        MozUserSelect: "none",
        userSelect: "none",
        border: 0,
        borderRadius: "2px",
        background: primaryColor,
        color: "#fff",
        outline: 0,
        "&:disabled": {
            background: "rgb(189, 189, 189)",
            cursor: "default"
        }
    },
    checkIcon: {
        opacity: 0.5,
        marginLeft: "32px",
    },
    dropzoneWrapper: {
        display: "block",
        clear: "both",
        width: "100%"
    },
    progressWrapper: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
    },
    filename: {
        marginBottom: "8px",
        fontSize: "16px",
        color: "#555",
    },
    row: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "50px",
        padding: "8px",
        overflow: "hidden",
        boxSizing: "border-box",
    }
};

class FileUploader extends Component {
    static contextType = WbSession;
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            error: false,
            errorMsg: "",
        };
    }
    
    onFilesAdded = (files) => {
        if( this.props.onFileSelected && typeof this.props.onFileSelected == "function" ){
            this.props.onFileSelected();
        }
		this.setState(prevState => ({
			files: prevState.files.concat(files)
		}));
        
        this.uploadFiles( files );
	}

	uploadFiles = async ( files ) => {
		this.setState({ uploading: true });
		const promises = [];
		files.forEach(file => {
			promises.push(this.sendRequest(file));
		});
		try {
			await Promise.all(promises);

			this.setState({ successfullUploaded: true, uploading: false });
		} catch (e) {
			// Not Production ready! Do some error handling here instead...
			this.setState({ successfullUploaded: true, uploading: false });
		}
	}

	sendRequest = (file) => {
		return new Promise( async (resolve, reject) => {
            let uploadSpeed = 125000; //Byes per second (125000B/s = 1Mbps)
            let fileSize = (file.size || 100000);
            let percentagePerSecond = (uploadSpeed / fileSize) * 100;
            let percentagePerIncrement = percentagePerSecond / 10;
            
            let simulateProgress = setInterval( () => {
                const copy = { ...this.state.uploadProgress };
                let percent = 0;
                if( typeof copy[file.name] != "undefined" && typeof copy[file.name].percentage != "undefined" ){
                    percent = copy[file.name].percentage;
                }
                if( percent > 50 ){
                    //slow it down a bit
                    percentagePerIncrement -= (percentagePerIncrement/10);
                    percentagePerIncrement = Math.max( percentagePerIncrement, 0.02 );
                }
                //calculate new % after 0.1s elapsed
                percent += percentagePerIncrement;
                percent = Math.min( percent, 90 ); //don't go over 90%
    			copy[file.name] = { percentage: percent };
    			this.setState({ uploadProgress: copy });
            }, 100 );
            
            let response = null;
            try{
                response = await this.context.uploadFile( file );
            } catch(err) {
                response = {
                    code: 'server_error'
                };
            }
            
            clearInterval( simulateProgress );
            
            const copy = { ...this.state.uploadProgress };
            if( response.id ){
                copy[file.name] = { state: "done", percentage: 100 };
            } else {
                let errorMsg = "Failed to upload file. Please check your connection and try again";
                copy[file.name] = { state: "error", percentage: 0 };
                this.setState({error: true, errorMsg, uploadProgress: copy});
            }
			this.setState({ uploadProgress: copy });
            this.props.onFileUploaded(response);
            resolve(response);
		});
	}

	renderProgress = (file) => {
        const { classes } = this.props;
		const uploadProgress = this.state.uploadProgress[file.name];
        let isError = (uploadProgress && uploadProgress.state === "error");
		if (this.state.uploading || this.state.successfullUploaded) {
			return (
				<div className={classes.progressWrapper}>
					<Progress
                        progress={uploadProgress ? uploadProgress.percentage : 0}
                        isError={isError}
                    />
                    { (!isError) && (
                        <Check
    						style={{
    							opacity:
    								uploadProgress && uploadProgress.state === "done" ? 0.5 : 0
    						}}
    					/>
                    )}
					{ (isError) && (
                        <Close
                            style={{
                                color: dangerColor[0],
                                fill: dangerColor[0],
                            }}
                        />
                    )}
				</div>
			);
		}
	}

	renderActions = () => {
        const { classes } = this.props;
		if (this.state.files.length) {
			return (
				<button
                    className={classes.button}
					onClick={() => {
    						this.setState({ files: [], successfullUploaded: false });
                            if( this.props.onCleared && typeof this.props.onCleared == "function" ){
                                this.props.onCleared();
                            }
                        }
					}
				>
					Clear
				</button>
			);
		} else {
			return "";
		}
	}
    
    componentWillReceiveProps(props) {
        const { clearUploads } = this.props;
        if ( clearUploads ) {
            this.setState({ //set to default
                files: [],
                uploading: false,
                uploadProgress: {},
                successfullUploaded: false,
                error: false,
                errorMsg: "",
            });
        }
    }

    render() {
        const { classes, className } = this.props;
        
        
        return (
            <div className={className}>
                <Snackbar
                    place="tc"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.errorMsg}
                    open={(this.state.error)}
                    closeNotification={() => this.setState({ error: false })}
                    close
                />
                <span className={classes.title}>Upload Files</span>
                <div className={classes.dropzoneWrapper}>
                    <Dropzone
                        onFilesAdded={this.onFilesAdded}
                    />
                </div>
                <div className={classes.content}>
                    <div className={classes.files}>
                        {this.state.files.map(file => {
                            return (
                                <div key={file.name} className="Row">
                                    <span className="Filename">{file.name}</span>
                                    {this.renderProgress(file)}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={classes.actions}>{this.renderActions()}</div>
            </div>
        );
    }
}

FileUploader.propTypes = {
    className: PropTypes.string,
    onFileUploaded: PropTypes.func.isRequired,
    onFileSelected: PropTypes.func,
    onCleared: PropTypes.func,
    clearUploads: PropTypes.bool,
};

Progress.defaultProps = {
    clearUploads: false,
};

export default withStyles(styles)(FileUploader);
