// @material-ui/icons
import { Dashboard, Person, DateRange, Message, ExitToApp } from "@material-ui/icons";
// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import BookInspection from "views/BookInspection/BookInspection.jsx";
import JobDetail from "views/JobDetail/JobDetail.jsx";
import InspectionDetail from "views/InspectionDetail/InspectionDetail.jsx";
import Notifications from "views/Notifications/Notifications.jsx";
import Login from "views/Login/Login.jsx";
import Logout from "views/Login/Logout.jsx";
import Register from "views/Login/Register.jsx";
import ResetPass from "views/Login/ResetPass.jsx";
import ResetPass2 from "views/Login/ResetPass2.jsx";

const dashboardRoutes = [
    {
        path: "dashboard", //page URL
        name: "Dashboard", //page title
        icon: Dashboard, //icon in menu
        component: DashboardPage, //page view controller
        layout: "/", //page path prefix (URL)
        inMenu: true, //display in sidebar menu?
        noUser: false, //allow when not logged in?
    },
    {
        path: "user",
        name: "User Profile",
        icon: Person,
        component: UserProfile,
        layout: "/",
        inMenu: false,
        inMenuMobile: true,
        noUser: false,
    },
    {
        path: "messages",
        name: "Messages",
        icon: Message,
        component: Notifications,
        layout: "/",
        inMenu: true,
        noUser: false,
    },
    {
        path: "book",
        name: "Edit Inspection",
        icon: DateRange,
        component: BookInspection,
        layout: "/",
        inMenu: false,
        noUser: false,
        supportIds: true,
    },
    {
        path: "book",
        name: "Book an Inspection",
        icon: DateRange,
        component: BookInspection,
        layout: "/",
        inMenu: true,
        noUser: false,
    },
    {
        path: "login",
        name: "Login",
        icon: Person,
        component: Login,
        layout: "/",
        inMenu: false,
        noUser: true,
    },
    {
        path: "register",
        name: "Register",
        icon: Person,
        component: Register,
        layout: "/",
        inMenu: false,
        noUser: true,
    },
    {
        path: "resetpass",
        name: "Reset Password",
        icon: Person,
        component: ResetPass,
        layout: "/",
        inMenu: false,
        noUser: true,
    },
    {
        path: "resetpass2",
        name: "Reset Password",
        icon: Person,
        component: ResetPass2,
        layout: "/",
        inMenu: false,
        noUser: true,
    },
    {
        path: "job",
        name: "Job Detail",
        icon: DateRange,
        component: JobDetail,
        layout: "/",
        inMenu: false,
        noUser: false,
        supportIds: true,
    },
    {
        path: "inspection",
        name: "Inspection",
        icon: DateRange,
        component: InspectionDetail,
        layout: "/",
        inMenu: false,
        noUser: false,
        supportIds: true,
    },
    {
        path: "logout",
        name: "Logout",
        icon: ExitToApp,
        component: Logout,
        layout: "/",
        inMenu: false,
        noUser: true,
        inMenuMobile: true,
        supportIds: true,
    },
];

export default dashboardRoutes;
