import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import CardFooter from "components/Card/CardFooter.jsx";
//import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomField from "components/CustomField/CustomField.jsx";
//react-ui core components
import {
    CircularProgress, //loading spinner
    Tooltip,
} from '@material-ui/core';
import { Redirect } from "react-router-dom";
// @material-ui/icons
import { AddAlert, ArrowBack } from "@material-ui/icons";

//classes
import WbSession from "classes/Session.jsx";
//colours
import { primaryColor } from "assets/jss/material-dashboard-react.jsx"

const styles = {
    loadingWrapper: {
        width: "100%",
        height: '250px',
    },
    loadingProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    cardTitleRight: {
        display: "inline-block",
        width: "auto",
        marginLeft: "1em",
    },
    unStyledButton: {
        display: "inline",
        padding: "0",
        boxShadow: "none !important",
        color: "inherit",
        fontWeight: "400",
        fontSize: "1em",
        appearance: "none",
        WebkitAppearance: "none",
        border: "none",
        background: "transparent",
        lineHeight: "1",
        cursor: "pointer",
    }
};

class InspectionDetail extends React.Component {
    static contextType = WbSession;
    constructor(props){
        super(props);
        this.state = {
            inspection_id: (props.match.params.id || 0),
            inspection: {},
            job: {},
            status: "",
            type: "",
            fields: [],
            inspections: [],
            attachments: [],
            base_url: "",
            inspection_statuses: [],
            
            
            error: false,
            errorMsg: '',
            loading: false, //handle animation for this
            minimal: false, //hide Owner column on small screens
            
            loadInspection: 0,
        };
    }
    
    getJobDetails = async () => {
        this.setState({loading:true});
        
        let response = null;
        try{
            response = await this.context.getInspectionDetails( this.state.inspection_id );
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        let data = response;
        
        if( typeof data == "object" && typeof data.code == "undefined" && typeof data.success != "undefined" && data.success ){
            //all good! loop through each of these to display
            let inspection = data.inspection;
            let status = data.status;
            let type = data.type;
            let fields = data.fields;
            let attachments = data.attachments;
            let base_url = data.base_url;
            let inspection_statuses = data.inspection_statuses;
            let job = data.job;
            let newState = {
                error:false,
                loading: false,
                inspection,
                status,
                type,
                fields,
                attachments,
                base_url,
                inspection_statuses,
                job,
            };
            
            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof data == "object" &&  typeof data.error != "undefined" ){
                errorMsg = data.error;
            }
            if( typeof data == "object" &&  typeof data.code != "undefined" ){
                errorMsg += " [code: "+data.code+"]";
            }
            this.setState({loading: false, error: true, errorMsg});
        }
    };
    
    formatField = (field, ind) => {
        let value = "";
        if( typeof this.state[field.label] != "undefined" ){
            value = this.state[field.label];
        } else if( typeof this.state.inspection[field.label] != "undefined" ){
            value = this.state.inspection[field.label];
        } else if( typeof this.state.job[field.label] != "undefined" ){
            value = this.state.job[field.label];
        }
        
        const { base_url } = this.state;
        
        return ( <CustomField key={ind} field={field} value={value} baseurl={base_url} /> );
    };
    
    handleRedirects = () => {
        if ( this.state.loadJob ) {
            let destination = "/job/"+this.state.inspection.idjob;
            return <Redirect to={destination} />
        }
    };
    handleBackClick = () => {
        this.setState({loadJob:1});
    };
    
    resize = () => {
        let mobile = true;
        if( window.innerWidth >= 700 ){
            mobile = false;
        }
        if( this.state.minimal !== mobile ){
            this.setState({minimal: mobile});
        }
    };
    
    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        
        //send AJAX request to server to get saved info
        this.getJobDetails( false );
    }
    
    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.handleRedirects()}
                <Snackbar
                    place="tc"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.errorMsg}
                    open={this.state.error}
                    closeNotification={() => this.setState({ error: false })}
                    close
                />
                <Card>
                    <CardHeader color="primary">
                        {this.state.inspection.idjob && (
                            <Tooltip
                                id="tooltip-top"
                                title="Back to Job Details"
                                placement="top"
                            >
                                <button href="#" onClick={this.handleBackClick} className={classes.unStyledButton}>
                                    <ArrowBack />
                                </button>
                            </Tooltip>
                        )}
                        <div className={classes.cardTitleRight}>
                            <h4 className={classes.cardTitleWhite}>Inspection Details</h4>
                            <p className={classes.cardCategoryWhite}>Viewing inspections and details about this inspection.</p>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {this.state.loading && (
                            <div className={classes.loadingWrapper}>
                                <CircularProgress size={24} className={classes.loadingProgress} />
                            </div>
                        )}
                        {!this.state.loading && (
                            <>
                                {(0===this.state.inspection.length) && "Inspection not found."}
                                {(0!==this.state.inspection.length) && (
                                    <GridContainer>
                                        {this.state.fields.map(this.formatField)}
                                    </GridContainer>
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            
            </div>
        );
    }
}

InspectionDetail.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(InspectionDetail);
