/*
 * Formats and displays a comment.
 */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {Table, TableRow, TableBody, TableCell} from "@material-ui/core";
// @material-ui/icons
import AccountCircle from "@material-ui/icons/AccountCircle";

import {
    defaultFont,
    grayColor,
    blackColor,
    hexToRgb
} from "assets/jss/material-dashboard-react.jsx";

const commentStyle = {
    comment: {
        border: "0",
        marginBottom: "30px",
        marginTop: "30px",
        borderRadius: "6px",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.87)",
        width: "100%",
        position: "relative",
        minWidth: "0",
        wordWrap: "break-word",
        fontSize: ".875rem",
        "& p": {
            marginTop: "0px",
            paddingTop: "0px",
        },
    },
    table: {
        marginBottom: "0",
        overflow: "visible",
    },
    tableRow: {
        position: "relative",
        borderBottom: "1px solid " + grayColor[5],
    },
    tableCell: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    icon:{
        color:"rgba(" + hexToRgb(blackColor) + ", 0.6)",
    },
    commentBody: {
        
    },
    commentMeta: {
        textAlign: "right",
        color: "rgba(" + hexToRgb(blackColor) + ", 0.6)",
    },
    commentDate: {
        
    },
    commentUser: {
        marginRight: "1em",
    },
};
class Comments extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            now: new Date()
        };
    }
    
    formatDate(date) {
        let formatted = date;
        //return formatted;
        // let commented = new Date(date); //NOTE: IOS can't cope with dates like this
        var t = date.split(/[- :]/);
        let commented = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
        let now = this.state.now;
        
        //format the time portion
        var hr = commented.getHours();
        var min = commented.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        var ampm = "AM";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "PM";
        }
        let formattedTime = hr + ":" + min + ampm;
        //get the day name
        let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let day = days[commented.getDay()];
        
        
        const diffTime = Math.abs(now.getTime() - commented.getTime());
        if( diffTime <= (1000 * 3600 * 24) ){ //less than 1 day ago
            if( diffTime <= (1000 * 3600) ){ //less than 1hr ago
                //show time in minutes
                let minutes = Math.round(diffTime / (1000 * 60));
                formatted = minutes + " minutes ago";
                if( 1 === minutes ){
                    formatted = minutes + " minute ago";
                } else if( 0 === minutes ){
                    formatted = 'Just now';
                }
            } else { //more than 1 hr ago (show time in hours)
                let hours = Math.round(diffTime / (1000 * 3600));
                formatted = hours + " hours ago";
                if( 1 === hours ){
                    formatted = hours + " hour ago";
                }
            }
        } else if ( diffTime <= (1000 * 3600 * 24 * 2) ){ //more than 1 day, but less than 2 (ie. yesterday)
            formatted = "Yesterday at "+formattedTime;
        } else if( diffTime <= (1000 * 3600 * 24 * 6) ) { //less than 1 week ago
            formatted = day+" at "+formattedTime;
        } else {
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            //format the date portion
            let date = commented.getDate();
            let month = months[commented.getMonth()];
        
            formatted = date + " " + month;
            if ( diffTime > (1000 * 3600 * 24 * 120) ) { //more than half a year ago (show year)
                formatted += " " + commented.getFullYear();
            }
            formatted += " at "+formattedTime;
        }
        
        return formatted; //FIXME:
    }
    
    tick() {
        this.setState(prevState => ({
            now: new Date()
        }));
    }
    
    componentDidMount() {
        this.interval = setInterval(() => this.tick(), 60000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    
    render() {
        const {
            classes,
            comments,
            ...rest
        } = this.props;
        return (
            <Table className={classes.table} {...rest}>
                <TableBody>
                    {comments.map( (comment, index) => {
                        if( '' === comment.message ){
                            return null;
                        }
                        return (
                            <TableRow key={index} className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                    <AccountCircle className={classes.icon} />
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                    <p className={classes.commentBody}>
                                        {comment.message}
                                    </p>
                                    <div className={classes.commentMeta}>
                                        <span className={classes.commentUser}>{comment.username} ({comment.useremail})</span>
                                        <span className={classes.commentDate}>{this.formatDate(comment.timestamp)}</span>
                                    </div>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }
}

Comments.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    comments: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(commentStyle)(Comments);
