/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import CountUp from 'react-countup';
// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import { LocalOffer, AddAlert, Update, Accessibility } from "@material-ui/icons";

// react plugin for creating charts
// import ChartistGraph from "react-chartist";
// core components
import Snackbar from "components/Snackbar/Snackbar.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
//import InspectionListing from "components/InspectionListing/InspectionListing.jsx";
import JobListing from "components/JobListing/JobListing.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
//import Danger from "components/Typography/Danger.jsx";
// import CardBody from "components/Card/CardBody.jsx";
//
// import {
//   dailySalesChart,
//   emailsSubscriptionChart,
//   completedTasksChart
// } from "variables/charts.jsx";

//classes
import WbSession from "classes/Session.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Dashboard extends React.Component {
    static contextType = WbSession;
    constructor(props){
        super(props);
        this.state = {
            inspPending: 0,
            inspAll: 0,
            daysRegistered: 0,
            
            loading: true,
            error: false,
            errorMsg: "",
        };
    }
    
    getStats = async () => {
        this.setState({loading:true});
        
        let response = null;
        try{
            response = await this.context.getStats();
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        if( typeof response == "object" && typeof response.code == "undefined" ){
            
            //all good! loop through each of these to display
            let newState = {
                error:false,
                loading: false,
                inspPending: parseInt(response.bookings_pending),
                inspAll: parseInt(response.bookings_all),
                daysRegistered: parseInt(response.days_registered),
            };
            
            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof response == "object" && typeof response.code != "undefined" ){
                errorMsg += " [msg: "+response.code+"]";
            }
            this.setState({error: true, errorMsg});
        }
    };
    
    componentDidMount(){
        //send AJAX request to server to get stats
        this.getStats();
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Snackbar
                  place="bc"
                  color="danger"
                  icon={AddAlert}
                  message={this.state.errorMsg}
                  open={(this.state.error)}
                  closeNotification={() => this.setState({ error: false })}
                  close
                />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <JobListing />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Icon>info_outline</Icon>
                                </CardIcon>
                                <p className={classes.cardCategory}>Total Inspections</p>
                                <h3 className={classes.cardTitle}>
                                    <CountUp start={0} end={this.state.inspPending} />/<CountUp start={0} end={this.state.inspAll} />
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    <LocalOffer />
                                    Total inspections pending/completed.
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Accessibility />
                                </CardIcon>
                                <p className={classes.cardCategory}>Days registered</p>
                                <h3 className={classes.cardTitle}>
                                    <CountUp start={0} end={this.state.daysRegistered} />
                                </h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    <Update />
                                    Days since account signup.
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

// <GridContainer>
//   <GridItem xs={12} sm={12} md={4}>
//     <Card chart>
//       <CardHeader color="success">
//         <ChartistGraph
//           className="ct-chart"
//           data={dailySalesChart.data}
//           type="Line"
//           options={dailySalesChart.options}
//           listener={dailySalesChart.animation}
//         />
//       </CardHeader>
//       <CardBody>
//         <h4 className={classes.cardTitle}>Daily Sales</h4>
//         <p className={classes.cardCategory}>
//           <span className={classes.successText}>
//             <ArrowUpward className={classes.upArrowCardCategory} /> 55%
//           </span>{" "}
//           increase in today sales.
//         </p>
//       </CardBody>
//       <CardFooter chart>
//         <div className={classes.stats}>
//           <AccessTime /> updated 4 minutes ago
//         </div>
//       </CardFooter>
//     </Card>
//   </GridItem>
//   <GridItem xs={12} sm={12} md={4}>
//     <Card chart>
//       <CardHeader color="warning">
//         <ChartistGraph
//           className="ct-chart"
//           data={emailsSubscriptionChart.data}
//           type="Bar"
//           options={emailsSubscriptionChart.options}
//           responsiveOptions={emailsSubscriptionChart.responsiveOptions}
//           listener={emailsSubscriptionChart.animation}
//         />
//       </CardHeader>
//       <CardBody>
//         <h4 className={classes.cardTitle}>Email Subscriptions</h4>
//         <p className={classes.cardCategory}>
//           Last Campaign Performance
//         </p>
//       </CardBody>
//       <CardFooter chart>
//         <div className={classes.stats}>
//           <AccessTime /> campaign sent 2 days ago
//         </div>
//       </CardFooter>
//     </Card>
//   </GridItem>
//   <GridItem xs={12} sm={12} md={4}>
//     <Card chart>
//       <CardHeader color="danger">
//         <ChartistGraph
//           className="ct-chart"
//           data={completedTasksChart.data}
//           type="Line"
//           options={completedTasksChart.options}
//           listener={completedTasksChart.animation}
//         />
//       </CardHeader>
//       <CardBody>
//         <h4 className={classes.cardTitle}>Completed Tasks</h4>
//         <p className={classes.cardCategory}>
//           Last Campaign Performance
//         </p>
//       </CardBody>
//       <CardFooter chart>
//         <div className={classes.stats}>
//           <AccessTime /> campaign sent 2 days ago
//         </div>
//       </CardFooter>
//     </Card>
//   </GridItem>
// </GridContainer>

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
