/*
 * Handles displaying the inspection booking form. Also allows editing existing bookings
 */
import React from "react";
import PropTypes from "prop-types";

// Custom components
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Pagination from "components/Pagination/Pagination.jsx";
import InspectionListing from "components/InspectionListing/InspectionListing.jsx";
// @material-ui/icons
import { AddAlert, Work, RemoveRedEye, Folder, AddCircle } from "@material-ui/icons";
// @material-ui/core components
import {
    Tabs,
    Tab,
    CircularProgress, //loading spinner
    Table, TableHead, TableBody, TableRow, TableCell, //tables
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import { Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

import customTabsStyle from "assets/jss/material-dashboard-react/components/customTabsStyle.jsx";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";

import {
    defaultFont,
    grayColor
} from "assets/jss/material-dashboard-react.jsx";

//classes
import WbSession from "classes/Session.jsx";

// core components
let styles = {
    loadingOverlay: {
        position: "relative",
        zIndex: "2",
        backgroundColor: "rgba(255,255,255,1)",
        width: "100%",
        height: "200px",
        top: "0",
        left: "0"
    },
    loader: {
        position: "absolute",
        zIndex: "3",
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    listTable: {
        marginBottom: "0",
        overflow: "visible",
        "& thead th": {
            paddingTop: "7px",
            paddingBottom: "7px",
            paddingLeft: "8px",
            paddingRight: "8px",
        },
    },
    
    listTitleRight: {
        textAlign: "right"
    },
    listTableRow: {
        position: "relative",
        borderBottom: "1px solid " + grayColor[5],
        "&:last-child": {
            borderBottom: "none",
        }
    },
    listTableCell: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableCellWide: {
        // width: "45%"
    },
    listCardFooter: {
        marginTop: "0",
    },
    
    tableActions: {
        display: "flex",
        border: "none",
        padding: "12px 8px !important",
        verticalAlign: "middle",
        justifyContent: "flex-end"
    },
    stats: {
        color: grayColor[0],
        display: "inline-flex",
        fontSize: "12px",
        lineHeight: "22px",
        "& svg": {
            top: "3px",
            width: "16px",
            height: "16px",
            position: "relative",
            marginRight: "3px",
            marginLeft: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            top: "3px",
            fontSize: "16px",
            position: "relative",
            marginRight: "3px",
            marginLeft: "3px"
        }
    },
    statsRight: {
        display: "inline-flex",
        float: "right",
    }
};

styles = { ...customTabsStyle, ...tasksStyle, ...tableStyle, ...styles };

class JobListing extends React.Component {
    static contextType = WbSession;
    constructor(props){
        super(props);
        this.state = {
            error: false,
            errorMsg: '',
            updated: false, //success message
            loadingTabs: true, //handle tab loading animation
            loading: true, //handle animation for this
            minimal: false, //hide Owner column on small screens
            
            tab: 0, //current tab number
            tabs: [], //array of all tabs (job statuses)
            jobs: [], //array of jobs from server
            
            totalPages: 1,
            currentPage: 1,
            
            loadJob: 0, //set to job ID to redirect to job page
            loadBookForm: false,
        };
    }
    
    getJobTabs = async () => {
        this.setState({loadingTabs:true});
        
        let response = null;
        try{
            response = await this.context.getJobTabs();
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        if( typeof response == "object" && typeof response.code == "undefined" ){
            //all good!y
            let newState = {
                error:false,
                loadingTabs: false,
                tabs: response
            };
            
            this.setState(newState);
            
            //get first set of Jobs
            this.getJobs( Object.keys(response)[0], 1 );
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof response == "object" && typeof response.code != "undefined" ){
                errorMsg += " [msg: "+response.code+"]";
            }
            this.setState({error: true, loadingTabs:false, loading:false, errorMsg});
        }
    };
    
    getJobs = async (status, page) => {
        if( typeof status == "undefined" ){
            status = 1;
        }
        if( typeof page == "undefined" || 0 === page ){
            page = 1;
        }
        
        this.setState({loading:true,currentPage:page});
        
        let response = null;
        try{
            response = await this.context.getJobs( status, page );
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        let data = response.data;
        
        if( typeof response == "object" && typeof response.code == "undefined" ){
            //all good! loop through each of these to display
            let totalPages = parseInt(response.pages);
            if( 0 === totalPages ){
                totalPages = 1; //Pagination doesn't like 0 pages (ie. no results)
            }
            let newState = {
                error:false,
                loading: false,
                jobs: data,
                totalPages: totalPages,
                currentPage: parseInt(response.cur),
            };
            
            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof response == "object" &&  typeof response.error != "undefined" ){
                errorMsg = response.error;
            }
            if( typeof response == "object" && typeof response.code != "undefined" ){
                errorMsg += " [msg: "+response.code+"]";
                
                if( 'no_agent_id' === response.code ){
                    //this is probably a new user account without an Agent ID - ignore this error
                    this.setState({loading:false});
                    return;
                }
            }
            this.setState({error: true, loading:false, errorMsg});
        }
    };
    
    
    formatDate = ( dt ) => {
        //let d = new Date( dt ); //NOTE: IOS can't cope with dates like this
        var t = dt.split(/[- :]/);
        let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //format the date portion
        let date = d.getDate();
        let month = months[d.getMonth()];
        if( date < 10 ){
            date = "0"+date;
        }
        let year = d.getFullYear();
        return date + " " + month + " " + year;
    };
    
    formatTime = ( time ) => {
        let hms = time.split(':');
        let d = new Date();
        d.setHours( hms[0] );
        d.setMinutes( hms[1] );
        
        var hr = d.getHours();
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        var ampm = "AM";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "PM";
        }
        return hr + ":" + min + ampm;; //FIXME: format this better
    };
    
    onPageChanged = ( data ) => {
        const { currentPage } = data;
        
        if( this.state.currentPage !== currentPage && 0 !== currentPage ){
            let tabs = this.formatTabsArray();
            this.getJobs( tabs[this.state.tab].tab, currentPage );
        }
    };
    
    tabChange = (event, tab) => {
        //lookup tab number from index
        let tabs = this.formatTabsArray();
        
        this.setState({tab:tab});
        this.getJobs( tabs[tab].tab, 1 );
    };
    
    formatTabsArray = () => {
        let tabs = [];
        let i = 0;
        for (let index in this.state.tabs) {
            let tabRow = {
                key: i++,
                tab: index,
                title: this.state.tabs[index],
            };
            
            tabs.push(tabRow);
        }
        return tabs;
    };
    
    formatTabContent = ( index ) => {
        const { classes } = this.props;
        let key = this.state.tab;
        let jobs = [];
        
        if( key !== index ){ //not the current page - leave it blank for now
            return (<div key={index}></div>);
        }
        
        let loader = (<div key={index} className={classes.loadingOverlay}><CircularProgress size={24} className={classes.loader} /></div>);
        if( this.state.loading ){
            return loader;
        }
        
        var self = this;
        this.state.jobs.forEach( function(job){
            let jobRow = {
                dateCreated: self.formatDate( job.initial_contact_date ),
                address: job.address_one,
                suburb: job.suburb,
                owner: job.cache_owner_name,
                id: job.idjob,
            }
            
            jobs.push(jobRow);
        } );
        
        if( 0 === key ){
            return ( <div key={index}><InspectionListing noHeader={true} jobs={jobs} scope={[0,1]} /></div> );
        }
        
        return (
            <div key={index}>
                <Table className={classes.listTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>Suburb</TableCell>
                            <TableCell>Owner</TableCell>
                            <TableCell>Date Requested</TableCell>
                            <TableCell className={classes.listTitleRight}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobs.map(value => (
                            <TableRow key={value.id} className={classes.listTableRow}>
                                <TableCell className={classes.listTableCell +" "+ classes.tableCellWide}>
                                    <Typography className={classes.mobileHeading}>Address</Typography>
                                    {value.address}
                                </TableCell>
                                <TableCell className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Suburb</Typography>
                                    {value.suburb}
                                </TableCell>
                                <TableCell className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Owner</Typography>
                                    {value.owner}
                                </TableCell>
                                <TableCell className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Date Requested</Typography>
                                    {value.dateCreated}
                                </TableCell>
                                <TableCell className={classes.tableActions}>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="View Job Details"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <IconButton
                                            aria-label="View"
                                            className={classes.tableActionButton}
                                            onClick={() => {this.handleEditClick(value.id)}}
                                        >
                                            <RemoveRedEye
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.edit
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                        {(0===jobs.length) && (
                            <TableRow key="0" className={classes.listTableRow}>
                                <TableCell className={classes.listTableCell +" "+ classes.tableCellWide}>
                                    No jobs found.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Pagination
                    numPages={this.state.totalPages}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                    currPage={this.state.currentPage}
                />
            </div>
        );
    };
    
    resize = () => {
        let mobile = true;
        if( window.innerWidth >= 700 ){
            mobile = false;
        }
        if( this.state.minimal !== mobile ){
            this.setState({minimal: mobile});
        }
    };
    
    handleRedirects = () => {
        if ( this.state.loadJob ) {
            let destination = "/job/"+this.state.loadJob;
            return <Redirect to={destination} />
        }
        if( this.state.loadBookForm ){
            let destination = "/book/";
            return <Redirect to={destination} />
        }
    };
    handleEditClick = ( jobId ) => {
        this.setState({loadJob:jobId});
    };
    handleBookClick = (e) => {
        e.preventDefault();
        this.setState({loadBookForm:true});
    };
    
    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        
        this.getJobTabs();
    }
    render() {
        const { classes } = this.props;
        
        let tabs = this.formatTabsArray();
        
        return (
            <>
                {this.handleRedirects()}
                <Snackbar
                  place="tc"
                  color="danger"
                  icon={AddAlert}
                  message={this.state.errorMsg}
                  open={(this.state.error)}
                  closeNotification={() => this.setState({ error: false })}
                  close
                />
                
                <Card>
                    <CardHeader color="primary">
                        <div className={classes.cardTitle}>Jobs: </div>
                        {(this.state.loadingTabs) && (
                            <CircularProgress size={24} className={classes.loader} />
                        )}
                        {(0!==tabs.length) && (
                            <Tabs
                                value={parseInt(this.state.tab)}
                                onChange={this.tabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                classes={{
                                    root: classes.tabsRoot,
                                    indicator: classes.displayNone,
                                    scrollButtons: classes.displayNone
                                }}
                            >
                                {tabs.map( (tab) => (
                                    <Tab
                                        classes={{
                                            root: classes.tabRootButton,
                                            selected: classes.tabSelected,
                                            wrapper: classes.tabWrapper
                                        }}
                                        key={tab.key}
                                        label={tab.title}
                                        icon={<Work />}
                                    />
                                ))}
                            </Tabs>
                        )}
                    </CardHeader>
                    <CardBody>
                        {tabs.map( (tab) => {
                            return this.formatTabContent( tab.key );
                        })}
                    </CardBody>
                    <CardFooter stats className={classes.listCardFooter}>
                        <div className={classes.stats}>
                            <Folder />
                            All jobs.
                        </div>
                        <div className={classes.stats + " " + classes.statsRight}>
                            <a
                                href="/book"
                                onClick={this.handleBookClick}
                                className={classes.statsRight}
                            >
                                <AddCircle />
                                New
                            </a>
                        </div>
                    </CardFooter>
                </Card>
            </>
        );
    }
}

JobListing.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(JobListing);
