// ##############################
// // // Login state and Wordpress connection
// #############################
import React from "react";
import Storage from "classes/Storage.jsx";
import Push from "classes/Push.jsx";

let wbStorage = new Storage();
let wbPush = new Push();

class Session {
    constructor() {
        this.logging = true;

        this.url = process.env.REACT_APP_ENDPOINT_URL + "wp-json";

        this.session_id = null;
        this.user_id = null;
        this.push_key = null;
        this.sessionError = function(e){};
        this.is_logging_in = false;

        this.restoreSession();
    }

    /**
     * Perform a login with username/password and set this.session_id and this.user_id
     */
    login = async ( user, pass ) => {
        if( this.logging ) console.log('Attempting login');
        this.is_logging_in = true;
        let response;
        try{
            response = await fetch(
                this.url + "/jwt-auth/v1/token",
                {
                    cache: 'no-store',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: 'post',
                    redirect: 'follow',
                    referrer: 'no-referrer',
                    body: JSON.stringify({
                        username: user,
                        password: pass
                    })
                }
            );
        } catch(e) {
            if( this.logging ) console.log('ERROR: Login request failed. Error message on next line:');
            if( this.logging ) console.log(e);

            return false;
        }

        if( this.logging ) console.log('Login request completed.');

        if( undefined === response ){
            if( this.logging ) console.log('Error: nothing returned from login request.');
            return false;
        }
        if( this.logging ) console.log('Attempting to decode login request json.');

        let ret = await response.json();
        if( this.logging ) console.log('Login request decoded:');
        if( this.logging ) console.log(ret);


        if( ret.token ){
            if( this.logging ) console.log('Login success! Saving session.');
            this.session_id = ret.token;
            if( ret.user_id ){
                this.user_id = ret.user_id;
            }
            this.save_session( this.session_id, this.user_id );

            const key = await this.getPushKey();

            this.subscribeToPush( key );
        }
        this.is_logging_in = false;
        //console.log(ret);
        return ret;
    }

    /**
     * Trigger a password reset email with username/email address
     */
    sendResetEmail = async ( user ) => {
        if( this.logging ) console.log('Attempting password reset email');
        const response = await fetch(
            this.url + "/agents-app/v1/resetpass",
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify({
                    username: user
                })
            }
        );

        let ret = await response.json();

        return ret;
    }

    /**
     * Trigger a password reset
     */
    resetPassword = async ( user, pass, key ) => {
        if( this.logging ) console.log('Attempting password reset');
        const response = await fetch(
            this.url + "/agents-app/v1/resetpassfinal",
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'post',
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify({
                    username: user,
                    password: pass,
                    key: key
                })
            }
        );

        let ret = await response.json();

        //login the user
        if( ret.success && ret.token ){
            if( this.logging ) console.log('Password reset success! Saving session.');
            this.session_id = ret.token.token;
            if( ret.user_id ){
                this.user_id = ret.user_id;
            }
            this.save_session( this.session_id, this.user_id );

            const key = await this.getPushKey();

            this.subscribeToPush( key );
        }

        return ret;
    }

    getPushKey = async () => {
        const response = await fetch(
            this.url + "/agents-app/v1/pushkey",
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();

        if( this.logging ) console.log(ret);

        if( ret.key && '' !== ret.key ){
            wbStorage.set_item( "pushKey", ret.key );
            this.push_key = ret.key;
        }
        return this.push_key;
    }

    subscribeToPush = async ( key ) => {
        const subscription = await wbPush.subscribeUser( key );

        if( !subscription ){
            console.log('Push token error');
            return false;
        }

        if( this.logging ) console.log('Push handler registered:');
        if( this.logging ) console.log( JSON.stringify(subscription) );
        //send to our server

        const response = await fetch(
            this.url + "/agents-app/v1/registerpush",
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'post',
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify({
                    push: subscription
                })
            }
        );

        let ret = await response.json();

        if( ret.success ){
            if( this.logging ) console.log('Successfully registered for push notifications');
        } else {
            console.log( ret );
        }
    }

    unSubscribeToPush = async () => {
        await wbPush.unSubscribe();
    }

    /**
     * Validate a session to ensure it's not expired
     *
     * NOTE: untested
     */
    validate_session = async () => {
        const response = await fetch(
            this.url + "/jwt-auth/v1/token/validate",
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'post',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();

        let valid = false;
        if( ret.data && ret.data.status && 200 === ret.data.status ){
            valid = true;
        }

        if( !valid ){
            if( this.logging ) console.log('session invalid - removing localstorage');
            this.logout();
        }

        return valid;
    }

    /**
     * Get the current user's profile details
     *
     * NOTE: untested
     */
    getProfile = async () => {
        if( !this.isLoggedIn || null == this.user_id || 'null' === this.user_id ){
            return {success:false,code:'not_logged_in',message:"You are not logged in."};
        }

        const response = await fetch(
            this.url + "/wp/v2/users/me",
            {
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let data = await response.json();
        this.check_session(data);

        return data;
    }

    /**
     * Gets a list of all available booking dates and times
     */
    getAvailableBookings = async () => {
        const response = await fetch(
            this.url + "/agents-app/v1/unavailable",
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Gets a list of all future bookings for the current user
     */
    getBookings = async ( archived, scope, page ) => {
        if( typeof archived == "undefined" ){
            archived = false;
        }
        if( typeof page == "undefined" ){
            page = 1;
        }

        let append = "?period=future";
        if( archived ){
            append = "?period=past";
        }
        if( 1 !== page ){
            append += '&page='+page;
        }

        if( null != scope ){
            if( Array.isArray(scope) ){
                append += '&scope='+scope.join();
            } else {
                append += '&scope='+scope;
            }
        }

        const response = await fetch(
            this.url + "/wp/v2/booking"+append,
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let totalPages = response.headers.get('X-WP-TotalPages');
        let ret = await response.json();
        this.check_session(ret);

        return {data:ret, pages: totalPages, cur:page };
    }

    /**
     * Gets details of a specific booking
     */
    getBooking = async ( post_id ) => {
        let route = '/'+post_id;

        const response = await fetch(
            this.url + "/wp/v2/booking"+route,
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Creates or updates a booking
     */
    updateBooking = async ( request, post_id ) => {
        let body = request;
        body.post_type = 'booking';
        body.status = 'publish';
        
        //filter request inputs (trim whitespace)
        const keys = Object.keys(request);
        for (const key of keys) {
            let value = request[key];
            if( typeof value == "string" ){
                request[key] = value.trim();
            }
        }

        if( typeof request.time != "undefined" ){
            body.start = request.time;
            delete body.time; //no longer needed in request
        }

        let method = 'post';
        let route = '';

        if( typeof request.date != "undefined" ){
            let dt = request.date;
            delete body.date; //no longer needed in request
            if( typeof dt == "string" && '' !== dt && null !== dt ){
                body.inspectiondate = dt;
            }
        }

        if( typeof post_id !== "undefined" && 0 !== post_id ){
            body.id = post_id;
            method = 'post';
            route = '/'+post_id;
        }

        if( '' === body.comment ){
            delete request.comment; //not needed if empty
        }

        const response = await fetch(
            this.url + "/wp/v2/booking"+route,
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: method,
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify(body)
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Cancels a booking
     */
    cancelBooking = async ( booking_id ) => {
        let method = 'delete';
        let route = '/'+booking_id;

        const response = await fetch(
            this.url + "/wp/v2/booking"+route,
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: method,
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Updates the current user's profile
     */
    updateProfile = async ( request ) => {
        let body = request;
        let method = 'post';

        const response = await fetch(
            this.url + "/wp/v2/users/me",
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: method,
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify(body)
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Gets some random stats for display on the dashboard
     */
    getStats = async () => {
        const response = await fetch(
            this.url + "/agents-app/v1/stats",
            {
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Gets all messages/notifications for this user
     */
    getNotifications = async () => {
        const response = await fetch(
            this.url + "/wp/v2/messages?per_page=20",
            {
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Update a messages/notifications to mark it dismissed
     */
    dismissNotification = async ( msgid ) => {
        let body = {
            dismissed: "on",
            id: msgid
        };
        let method = 'post';
        const response = await fetch(
            this.url + "/wp/v2/messages/"+msgid,
            {
                cache: 'no-store',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: method,
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify(body)
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Fetch a list of all possible job statuses (InspectionApps)
     */
    getJobTabs = async () => {
        const response = await fetch(
            this.url + "/agents-app/v1/jobstatuses",
            {
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Fetch a list of all jobs from this Agent (InspectionApps)
     *
     * @param status (int) The status number to search for.
     */
    getJobs = async ( status, page ) => {
        if( typeof page == "undefined" ){
            page = 1;
        }

        let append = "?status="+parseInt(status);
        if( 1 !== page ){
            append += '&page='+page;
        }

        const response = await fetch(
            this.url + "/agents-app/v1/lookupjobs"+append,
            {
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Get details & inspections about a specific job (InspectionApps)
     *
     * @param jobid (int) The job number to get details for.
     */
    getJobDetails = async ( jobid ) => {
        let append = "?job="+parseInt(jobid);

        const response = await fetch(
            this.url + "/agents-app/v1/jobdetails"+append,
            {
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Get details about a specific inspection (InspectionApps)
     *
     * @param inspectionid (int) The inspection number to get details for.
     */
    getInspectionDetails = async ( inspectionid ) => {
        let append = "?inspection="+parseInt(inspectionid);

        const response = await fetch(
            this.url + "/agents-app/v1/inspectiondetails"+append,
            {
                cache: 'default',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'get',
                redirect: 'follow',
                referrer: 'no-referrer'
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /**
     * Uploads a file to the Wordpress Media Library. Returns the file ID.
     *
     * @param file (int) The inspection number to get details for.
     */
    uploadFile = async ( file, name, caption ) => {
        let formData = new FormData();
        formData.append( 'file', file );
        if( typeof name !== "undefined" )
            formData.append( 'title', name );
        if( typeof caption !== "undefined" )
            formData.append( 'title', caption );


        const response = await fetch(
            this.url + "/wp/v2/media",
            {
                cache: 'default',
                headers: {
                    'Authorization': 'Bearer '+this.session_id,
                },
                method: 'post',
                redirect: 'follow',
                referrer: 'no-referrer',
                body: formData
            }
        );

        let ret = await response.json();
        this.check_session(ret);

        return ret;
    }

    /*
	 * checks localstorage and restores previous session
	 */
	restoreSession(){
		if( this.logging ) console.log('attempting to restore session');
		let session_id = wbStorage.get_item("sessionID");
		if( null != session_id && 'null' !== session_id ){
			if( this.logging ) console.log('session restored: '+session_id);
			this.session_id = session_id;

            //TODO: maybe fire an event here or something
		}
		let user_id = wbStorage.get_item("userID");
		if( user_id !== null ){
			if( this.logging ) console.log('user_id restored: '+user_id);
			this.user_id = user_id;

            //TODO: maybe fire an event here or something
		}

		let push_key = wbStorage.get_item("pushKey");
		if( push_key !== null ){
			if( this.logging ) console.log('push_key restored: '+push_key);
			this.push_key = push_key;

            //TODO: maybe fire an event here or something
		} else {
            this.getPushKey();
        }
	}

    /*
	 * saves the current session to localstorage
	 */
	save_session( session, user_id ){
		wbStorage.set_item( "sessionID", session );
		wbStorage.set_item( "userID", user_id );
	}

    /*
     * To be called with the result of every Ajax request - checks to ensure that we are still logged in
     *  If not, triggers this.logout()
     */
    check_session( data ){
        let result = true;
        let invalidCodes = [
            'jwt_auth_invalid_token',
            'jwt_auth_no_auth_header',
            'jwt_auth_bad_auth_header',
            'jwt_auth_bad_config',
            'jwt_auth_bad_iss',
            'jwt_auth_bad_request',
        ];

        if( typeof data.code != "undefined" && invalidCodes.includes(data.code) ){
            //this.logout();
            result = false;

            let sessionErrorMsg = (data.message || "Your login session has expired. Click here to log in again.");
            this.sessionError( sessionErrorMsg );
        }

        return result;
    }

    /*
	 * removes the current session from localstorage
	 */
	logout(){
        if( this.is_logging_in )
            return;
        if( this.logging ) console.log('Logout triggered');
		wbStorage.set_item( "sessionID", null );
		wbStorage.set_item( "userID", null );
		wbStorage.set_item( "pushKey", null );
        this.user_id = null;
        this.session_id = null;
        this.push_key = null;

        this.unSubscribeToPush();
	}

    /*
	 * Checks whether we are currently logged in (but does not validate session)
	 */
    isLoggedIn(){
        if( this.logging ) console.log('Checking session: '+this.session_id);
        return (null != this.session_id && 'null' !== this.session_id);
    }

    /*
     * Passes us the setState function of the primary controller class, allowing us to
     * trigger notifications when a user's session expires.
     */
    passControllerErrorFunction( errorFunction ){
        this.sessionError = errorFunction;
    }
}

const WbSession = React.createContext(new Session());

export default WbSession;
