/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
  warningColor,
  primaryColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  defaultFont
} from "assets/jss/material-dashboard-react.jsx";

const tableStyle = {
    warningTableHeader: {
        color: warningColor[0]
    },
    primaryTableHeader: {
        color: primaryColor[0]
    },
    dangerTableHeader: {
        color: dangerColor[0]
    },
    successTableHeader: {
        color: successColor[0]
    },
    infoTableHeader: {
        color: infoColor[0]
    },
    roseTableHeader: {
        color: roseColor[0]
    },
    grayTableHeader: {
        color: grayColor[0]
    },
    table: {
        marginBottom: "0",
        width: "100%",
        maxWidth: "100%",
        backgroundColor: "transparent",
        borderSpacing: "0",
        borderCollapse: "collapse"
    },
    tableHeadCell: {
        color: "inherit",
        ...defaultFont,
        "&, &$tableCell": {
            fontSize: "1em"
        }
    },
    tableCell: {
        ...defaultFont,
        lineHeight: "1.42857143",
        padding: "12px 8px",
        verticalAlign: "middle",
        fontSize: "0.8125rem"
    },
    tableResponsive: {
        width: "100%",
        overflowX: "auto"
    },
    tableHeadRow: {
        height: "56px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle"
    },
    tableBodyRow: {
        height: "48px",
        color: "inherit",
        display: "table-row",
        outline: "none",
        verticalAlign: "middle"
    },
    mobileHeading: {
        display: "none"
    },
    "@media (max-width: 959px)": {
        mobileHeading: {
            display: "block",
            fontWeight: "600"
        },
        listTable: {
            display: "block",
            "& thead": {
                display: "none"
            },
            "& tbody": {
                display: "block"
            },
            "& tr": {
                display: "block",
                width: "100%",
                clear: "both"
            },
            "& tr:after": {
                content: " ",
                display: "table",
                clear: "both"
            },
            "& td": {
                display: "inline-block",
                width: "50%",
                margin: 0,
                padding: "1em 1em",
                boxSizing: "border-box"
            },
            "& td:nth-child(2n-1)": {
                clear: "left",
                paddingLeft: "0"
            },
            "& td:nth-child(2n)": {
                clear: "right",
                paddingRight: "0"
            }
        },
        tableActions: {
            width: "100% !important",
            display: "flex !important"
        }
    }
};

export default tableStyle;
