import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
//react-ui core components
import {
    CircularProgress, //loading spinner
    Table, TableHead, TableBody, TableRow, TableCell, //tables
    Tooltip,
    IconButton,
} from '@material-ui/core';
import { Redirect } from "react-router-dom";
// @material-ui/icons
import { AddAlert, RemoveRedEye, Edit } from "@material-ui/icons";

//classes
import WbSession from "classes/Session.jsx";
//colours
import { primaryColor, hexToRgb, defaultFont, grayColor } from "assets/jss/material-dashboard-react.jsx";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";

const customStyles = {
    loadingWrapper: {
        width: "100%",
        height: '250px',
    },
    loadingProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    listTable: {
        marginBottom: "0",
        overflow: "visible",
    },
    listTitleRight: {
        textAlign: "right"
    },
    listTableRow: {
        position: "relative",
        borderBottom: "1px solid " + grayColor[5],
        "&:last-child": {
            borderBottom: "none",
        }
    },
    listTableRowActive: {
        background: "rgba("+hexToRgb(grayColor[7])+",0.1)",
    },
    listTableCell: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableCellWide: {
        width: "65%"
    },
    msgParagraph: {
        minHeight: "1em",
        margin: "0"
    },
    tableActions: {
        display: "flex",
        border: "none",
        padding: "12px 8px !important",
        verticalAlign: "middle",
        justifyContent: "flex-end"
    },
    "@media screen and (max-width:959px)": {
        notificationTitle: {
            fontWeight: "600 !important",
            width: "100%",
            padding: "1em 1em 0 1em !important"
        },
        notificationMsg: {
            width: "100% !important",
            padding: "0 1em 0 1em !important"
        },
        notificationDate: {
            width: "100% !important",
            textAlign: "right",
            paddingTop: "0 1em 0 1em !important",
            color: "rgba("+hexToRgb(grayColor[7])+",0.4)",
        }
    }
};

const styles = { ...tableStyle, ...customStyles };

class Notifications extends React.Component {
    static contextType = WbSession;
    constructor(props){
        super(props);
        this.state = {
            notifications: [],
            
            error: false,
            errorMsg: '',
            loading: false, //handle animation for this
            minimal: false, //hide extra detail columns on small screens
            
            loadBooking: 0,
            loadJob: 0,
            
            now: new Date()
        };
    }
    
    handleEditClick = ( bookingId, messageId ) => {
        this.dismissMessage(messageId);
        this.setState({loadBooking:bookingId});
    };
    handleEditJobClick = ( jobId, messageId ) => {
        this.dismissMessage(messageId);
        this.setState({loadJob:jobId});
    };
    
    getNotifications = async () => {
        this.setState({loading:true});
        
        let response = null;
        try{
            response = await this.context.getNotifications();
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        let data = response;
        
        if( typeof data.code == "undefined" ){
            //all good! loop through each of these to display
            let notifications = data;
            let newState = {
                error:false,
                loading: false,
                notifications,
            };
            
            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof data == "object" &&  typeof data.error != "undefined" ){
                errorMsg = data.error;
            }
            if( typeof data == "object" &&  typeof data.code != "undefined" ){
                errorMsg += " [code: "+data.code+"]";
            }
            this.setState({loading: false, error: true, errorMsg});
        }
    };
    
    dismissMessage = async ( messageId ) => {
        let response = null;
        try{
            response = await this.context.dismissNotification(messageId);
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        console.log(response);
    };
    
    formatNotification = ( notification ) => {
        const { classes } = this.props;
        let id = notification.id;
        let date = this.getDate(notification.date);
        let title = notification.title.rendered;
        let msg = notification.message;
        let dismissed = notification.dismissed;
        let relJob = notification.related_job;
        let relBooking = notification.related_booking;
        
        let rowClasses = classes.listTableRow;
        if( 'on' !== dismissed ){
            rowClasses += " " + classes.listTableRowActive;
        }
        
        let innerText = msg.split('\r\n').map((item, i) => {
            return <p key={i} className={classes.msgParagraph}>{item}</p>;
        });
        
        return (
            <TableRow key={id} className={rowClasses}>
                <TableCell className={classes.listTableCell+" "+classes.notificationTitle}>
                    {title}
                </TableCell>
                <TableCell className={classes.listTableCell+" "+classes.notificationMsg}>
                    {innerText}
                </TableCell>
                <TableCell className={classes.listTableCell+" "+classes.notificationDate}>
                    {this.formatDate(date)}
                </TableCell>
                <TableCell className={classes.listTableCell + " " + classes.tableActions}>
                    {(''!==relJob&&null!==relJob) && (
                        <Tooltip
                            id="tooltip-top"
                            title="View Job"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <IconButton
                                aria-label="View Job"
                                className={classes.tableActionButton}
                                onClick={() => {this.handleEditJobClick(relJob, id)}}
                            >
                                <RemoveRedEye
                                    className={
                                        classes.tableActionButtonIcon + " " + classes.edit
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                    {(''!==relBooking && null!==relBooking) && (
                        <Tooltip
                            id="tooltip-top"
                            title="Edit Booking"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <IconButton
                                aria-label="Edit Booking"
                                className={classes.tableActionButton}
                                onClick={() => {this.handleEditClick(relBooking, id)}}
                            >
                                <Edit
                                    className={
                                        classes.tableActionButtonIcon + " " + classes.edit
                                    }
                                />
                            </IconButton>
                        </Tooltip>
                    )}
                </TableCell>
            </TableRow>
        );
    };
    
    handleRedirects = () => {
        if ( this.state.loadBooking ) {
            let destination = "/book/"+this.state.loadBooking;
            return <Redirect to={destination} />
        } else if ( this.state.loadJob ){
            let destination = "/job/"+this.state.loadJob;
            return <Redirect to={destination} />
        }
    };
    
    formatDate = (commented) => {
        let formatted = '';
        //let commented = new Date(date);
        let now = this.state.now;
        
        //format the time portion
        var hr = commented.getHours();
        var min = commented.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        var ampm = "AM";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "PM";
        }
        let formattedTime = hr + ":" + min + ampm;
        //get the day name
        let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        let day = days[commented.getDay()];
        
        
        const diffTime = Math.abs(now.getTime() - commented.getTime());
        if( diffTime <= (1000 * 3600 * 24) ){ //less than 1 day ago
            if( diffTime <= (1000 * 3600) ){ //less than 1hr ago
                //show time in minutes
                let minutes = Math.round(diffTime / (1000 * 60));
                formatted = minutes + " minutes ago";
                if( 1 === minutes ){
                    formatted = minutes + " minute ago";
                } else if( 0 === minutes ){
                    formatted = 'Just now';
                }
            } else { //more than 1 hr ago (show time in hours)
                let hours = Math.round(diffTime / (1000 * 3600));
                formatted = hours + " hours ago";
                if( 1 === hours ){
                    formatted = hours + " hour ago";
                }
            }
        } else if ( diffTime <= (1000 * 3600 * 24 * 2) ){ //more than 1 day, but less than 2 (ie. yesterday)
            formatted = "Yesterday at "+formattedTime;
        } else if( diffTime <= (1000 * 3600 * 24 * 6) ) { //less than 1 week ago
            formatted = day+" at "+formattedTime;
        } else {
            let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            //format the date portion
            let date = commented.getDate();
            let month = months[commented.getMonth()];
            
            formatted = date + " " + month;
            if ( diffTime > (1000 * 3600 * 24 * 120) ) { //more than half a year ago (show year)
                formatted += " " + commented.getFullYear();
            }
            formatted += " at "+formattedTime;
        }
        
        return formatted; //FIXME:
    };
    
    getDate = (dt) =>{
        let d = new Date( '1970-01-01' );
        if( typeof dt == "string" && '' !== dt && null !== dt ){
            d = new Date( dt );
        }
        return d;
    };
    
    resize = () => {
        let mobile = true;
        if( window.innerWidth >= 700 ){
            mobile = false;
        }
        if( this.state.minimal !== mobile ){
            this.setState({minimal: mobile});
        }
    };
    
    tick() {
        this.setState(prevState => ({
            now: new Date()
        }));
    }
    
    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        
        this.interval = setInterval(() => this.tick(), 60000);
        
        //send AJAX request to server to get saved info
        this.getNotifications( false );
    }
    
    render() {
        const { classes } = this.props;
        return (
            <div key={0}>
                {this.handleRedirects()}
                <Snackbar
                    place="tc"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.errorMsg}
                    open={this.state.error}
                    closeNotification={() => this.setState({ error: false })}
                    close
                />
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Messages</h4>
                        <p className={classes.cardCategoryWhite}>Viewing all messages and notifications.</p>
                    </CardHeader>
                    <CardBody>
                        {this.state.loading && (
                            <div className={classes.loadingWrapper}>
                                <CircularProgress size={24} className={classes.loadingProgress} />
                            </div>
                        )}
                        {!this.state.loading && (
                        <Table className={classes.listTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell className={classes.listTitleRight}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(0===this.state.notifications.length) && (
                                    <TableRow key="0" className={classes.listTableRow}>
                                        <TableCell className={classes.listTableCell +" "+ classes.tableCellWide}>
                                            No notifications.
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(0!==this.state.notifications.length) && (
                                    <>
                                        {this.state.notifications.map(this.formatNotification)}
                                    </>
                                )}
                            </TableBody>
                        </Table>
                        )}
                    </CardBody>
                </Card>
            
            </div>
        );
    }
}

Notifications.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(Notifications);
