/*
 * Formats the links in the header navigation and dropdown at the top right
 *  NOTE: shows in the sidebar on small devices
 */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
// core components
import Button from "components/CustomButtons/Button.jsx";
import { Redirect } from "react-router-dom";
//classes
import WbSession from "classes/Session.jsx";

// Routes
import { NavLink } from "react-router-dom";

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

class AdminNavbarLinks extends React.Component {
    static contextType = WbSession;
    state = {
        openProfile: false,
        logout: false,
    };
    handleToggleProfile = () => {
        this.setState(state => ({ openProfile: !state.openProfile }));
    };
    handleCloseProfile = event => {
        if (this.anchorProfile.contains(event.target)) {
            return;
        }
        this.setState({ openProfile: false });
    };
    doLogout = () => {
        this.setState({logout: true});
    };
    handleRedirects = () => {
        if (this.state.logout) {
            this.context.logout();
            this.setState({logout: false});
            return <Redirect to='/login' />
        }
    };
    render() {
    const { classes } = this.props;
    const { openProfile } = this.state;
    return (
          <div>
            {this.handleRedirects()}
            <NavLink
                to="/dashboard"
                style={{color:'inherit'}}
            >
                <Button
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-label="Dashboard"
                    className={classes.buttonLink}
                >
                    <Dashboard className={classes.icons} />
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Dashboard</p>
                    </Hidden>
                </Button>
            </NavLink>
            <NavLink
                to="/messages"
                style={{color:'inherit'}}
                className={classes.manager}>
                <Button
                    buttonRef={node => {
                        this.anchorNotification = node;
                    }}
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-haspopup="true"
                    onClick={this.handleToggleNotification}
                    className={classes.buttonLink}
                >
                    <Notifications className={classes.icons} />
                    {/*<span className={classes.notifications}>5</span>*/}
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>
                            Message(s)
                        </p>
                    </Hidden>
                </Button>
            </NavLink>
            <div className={classes.manager}>
                <Button
                    buttonRef={node => {
                        this.anchorProfile = node;
                    }}
                    color={window.innerWidth > 959 ? "transparent" : "white"}
                    justIcon={window.innerWidth > 959}
                    simple={!(window.innerWidth > 959)}
                    aria-haspopup="true"
                    onClick={this.handleToggleProfile}
                    className={classes.buttonLink}
                >
                    <Person className={classes.icons} />
                    <Hidden mdUp implementation="css">
                        <p className={classes.linkText}>Profile</p>
                    </Hidden>
                </Button>
                <Poppers
                    open={openProfile}
                    anchorEl={this.anchorProfile}
                    transition
                    disablePortal
                    className={
                        classNames({ [classes.popperClose]: !openProfile }) +
                        " " +
                        classes.popperNav
                    }
                >
                    {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        id="profile-menu-list-grow"
                        style={{
                            transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={this.handleCloseProfile}>
                                <MenuList role="menu">
                                    <NavLink
                                        to="/user"
                                    >
                                        <MenuItem
                                            onClick={this.handleCloseProfile}
                                            className={classes.dropdownItem}
                                        >
                                            Profile
                                        </MenuItem>
                                    </NavLink>
                                    {/*<MenuItem
                                        onClick={this.handleCloseProfile}
                                        className={classes.dropdownItem}
                                    >
                                        Settings
                                    </MenuItem>*/}
                                    <Divider light />
                                    <div onClick={this.doLogout}>
                                        <MenuItem
                                            onClick={this.handleCloseProfile}
                                            className={classes.dropdownItem}
                                        >
                                            Logout
                                        </MenuItem>
                                    </div>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                    )}
                </Poppers>
            </div>
          </div>
        );
    }
}

AdminNavbarLinks.propTypes = {
  classes: PropTypes.object
};

export default withStyles(headerLinksStyle)(AdminNavbarLinks);
