import React, { Fragment } from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
//import CardFooter from "components/Card/CardFooter.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CustomField from "components/CustomField/CustomField.jsx";
import Comments from "components/Comments/Comments.jsx";
//react-ui core components
import {
    TextField,
    CircularProgress, //loading spinner
} from '@material-ui/core';
// @material-ui/icons
import { AddAlert } from "@material-ui/icons";

//classes
import WbSession from "classes/Session.jsx";
//colours
import { primaryColor } from "assets/jss/material-dashboard-react.jsx"

const styles = {
    loadingWrapper: {
        width: "100%",
        height: '250px',
    },
    loadingProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    sectionTitle: {
        fontSize: "1.4em",
        marginTop: "3em",
        marginBottom: "0",
        "& + .MuiGrid-root .MuiFormControl-root": {
            marginTop: "0",
        },
        "&:first-child": {
            marginTop: "1em",
        }
    },
    textInput: {
        display: "block",
        width: "100%",
        marginTop: "1.6em",
        color: "#aaa",
        fontSize: "14px",
    },
    buttonWrapper: {
        position: 'relative',
        marginTop: "2em",
    },
    buttonProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
};

class JobDetail extends React.Component {
    static contextType = WbSession;
    constructor(props){
        super(props);
        this.state = {
            job_id: (props.match.params.id || 0),
            job: {},
            status: "",
            type: "",
            fields: [],
            inspections: [],
            attachments: [],
            base_url: "",
            inspection_statuses: {},
            
            prevComments: [], //array of all previous comments
            bookingId: null, //ID of booking in Wordpress (for adding new comments)
            newComment: "",
            saving: false, //set to true while saving new comment
            
            error: false,
            errorMsg: '',
            loading: false, //handle animation for this
            minimal: false, //hide Owner column on small screens
            
            loadInspection: 0,
        };
    }
    
    handleChange = (newValue) => {
        let newState = {error: false};
        newState.newComment = newValue;
        this.setState(newState);
    };
    
    getJobDetails = async () => {
        this.setState({loading:true});
        
        let response = null;
        try{
            response = await this.context.getJobDetails( this.state.job_id );
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        let data = response;
        
        if( typeof data == "object" && typeof data.code == "undefined" && typeof data.success != "undefined" && data.success ){
            //all good! loop through each of these to display
            let job = data.job;
            let inspections = data.inspections;
            let status = data.status;
            let type = data.type;
            let fields = data.fields;
            let attachments = data.attachments;
            let base_url = data.base_url;
            let inspection_statuses = data.inspection_statuses;
            let prevComments = data.comments;
            let bookingId = data.booking;
            let newState = {
                error:false,
                loading: false,
                job,
                inspections,
                status,
                type,
                fields,
                attachments,
                base_url,
                inspection_statuses,
                prevComments,
                bookingId
            };
            
            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof data == "object" &&  typeof data.error != "undefined" ){
                errorMsg = data.error;
            }
            if( typeof data == "object" &&  typeof data.code != "undefined" ){
                errorMsg += " [code: "+data.code+"]";
            }
            this.setState({loading: false, error: true, errorMsg});
        }
    };
    
    formatField = (field, ind) => {
        let value = "";
        if( typeof this.state[field.label] != "undefined" ){
            value = this.state[field.label];
        } else if( typeof this.state.job[field.label] != "undefined" ){
            value = this.state.job[field.label];
        } else if( typeof this.state.job.custom_field_values[field.label] != "undefined" ){
            value = this.state.job.custom_field_values[field.label];
        }
        
        const { base_url, inspection_statuses } = this.state;
        
        return ( <CustomField key={ind} field={field} value={value} baseurl={base_url} statuses={inspection_statuses} /> );
    };
    
    postNewComment = async () => {
        if( this.state.saving )
            return;
            
        let post_id = this.state.bookingId;
        let newComment = this.state.newComment;
        if( '' === newComment ){
            this.setState({saving: false, error: true, errorMsg: "Please enter a comment."});
            return;
        }
        
        if( null === post_id || '' === post_id ){
            this.setState({saving: false, error: true, errorMsg: "Error: Cannot determine Booking ID to post comment."});
            return;
        }
        
        let response = null;
        
        let fields = {
            comment: newComment
        };
        
        try{
            response = await this.context.updateBooking( fields, post_id );
        } catch(err) {
            console.log(err);
            response = {
                code: 'server_error'
            };
        }
        if(this.logging) console.log('Server Reponse: ');
        if(this.logging) console.log( response );
        
        //check results, set loading to false, display errors or set loggedIn to true
        if( typeof response == "object" && typeof response.id != "undefined" && response.id ){
            // update comment fields with server-validated content
            let newState = {
                saving: false,
                error: false,
                newComment: "", //blank this out as it is now saved
            };
            if( typeof response.comment != "undefined" ){
                newState.prevComments = response.comment;
            }
            this.setState(newState);
        } else {
            let errorMsg = 'Error: failed to add comment.';
            if( typeof response == "object" && response.message ){
                errorMsg = response.message;
            }
            
            this.setState({saving:false, error:true, errorMsg});
        }
        
        console.log(newComment);
    };
    
    resize = () => {
        let mobile = true;
        if( window.innerWidth >= 700 ){
            mobile = false;
        }
        if( this.state.minimal !== mobile ){
            this.setState({minimal: mobile});
        }
    };
    
    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        
        //send AJAX request to server to get saved info
        this.getJobDetails( false );
    }
    
    render() {
        const { classes } = this.props;
        return (
            <div key={0}>
                <Snackbar
                    place="tc"
                    color="danger"
                    icon={AddAlert}
                    message={this.state.errorMsg}
                    open={this.state.error}
                    closeNotification={() => this.setState({ error: false })}
                    close
                />
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Job Details</h4>
                        <p className={classes.cardCategoryWhite}>Viewing inspections and details about this job.</p>
                    </CardHeader>
                    <CardBody>
                        {this.state.loading && (
                            <div className={classes.loadingWrapper}>
                                <CircularProgress size={24} className={classes.loadingProgress} />
                            </div>
                        )}
                        {!this.state.loading && (
                            <Fragment key={0}>
                                {(0===this.state.job.length) && "Job not found."}
                                {(0!==this.state.job.length) && (
                                    <>
                                        <GridContainer>
                                            {this.state.fields.map(this.formatField)}
                                        </GridContainer>
                                        {(null!==this.state.bookingId) && (
                                            <GridContainer>
                                            {/* Comments field - displays multiple comments in a table */}
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h3 className={classes.sectionTitle}>Comments</h3>
                                                    <Comments comments={this.state['prevComments']} />
                                                    <TextField
                                                        label="New Comment"
                                                        id="comment"
                                                        type="text"
                                                        className={classes.textInput}
                                                        value={this.state.newComment}
                                                        fullWidth
                                                        multiline
                                                        onChange={(event) => this.handleChange(event.target.value)}
                                                        InputProps={{
                                                            readOnly: (this.state.saving || this.state.readOnly)
                                                        }}
                                                    />
                                                    <div className={classes.buttonWrapper}>
                                                        <Button
                                                            className={classes.button}
                                                            fullWidth
                                                            color="primary"
                                                            type="submit"
                                                            onClick={this.postNewComment}
                                                            disabled={('' === this.state.newComment || this.state.saving || this.state.readOnly)}
                                                        >Add Comment</Button>
                                                        {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                    </div>
                                                </GridItem>
                                            </GridContainer>
                                        )}
                                    </>
                                )}
                            </Fragment>
                        )}
                    </CardBody>
                </Card>
            
            </div>
        );
    }
}

JobDetail.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(JobDetail);
