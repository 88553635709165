import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

// core components
import Admin from "layouts/Admin.jsx";
//classes
// import WbSession from "classes/Session.jsx";

import "assets/css/material-dashboard-react.css?v=1.7.0";

const hist = createBrowserHistory();

let redirectTo = "/dashboard";
// if( !wbSession.isLoggedIn() ){ //if is not logged in? //FIXME
//     redirectTo = "/login";
// }

ReactDOM.render(
    <Router history={hist}>
        <Switch>
            <Route path="/" component={Admin} />
            <Redirect from="/" to={redirectTo} />
        </Switch>
    </Router>,
    document.getElementById("root")
);
