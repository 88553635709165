import React, { Component } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { primaryColor, dangerColor } from "assets/jss/material-dashboard-react.jsx"

let styles = {
    progressBar: {
        width: "100%",
        height: "8px",
        backgroundColor: primaryColor[3],
        borderRadius: "5px",
    },
    progress: {
        backgroundColor: primaryColor[0],
        height: "100%",
        margin: "0",
        borderRadius: "5px",
    },
    progressError: {
        width: "100%",
        height: "8px",
        backgroundColor: dangerColor[3],
        borderRadius: "5px",
    },
};

class Progress extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { classes, isError } = this.props;
        
        return (
            <div className={ (isError) ? classes.progressError : classes.progressBar}>
                <div
                    className={classes.progress}
                    style={{ width: this.props.progress + "%" }}
                />
            </div>
        );
    }
}

Progress.propTypes = {
    isError: PropTypes.bool,
};

Progress.defaultProps = {
    isError: false,
};

export default withStyles(styles)(Progress);
