import React from "react";
import { Redirect, NavLink } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from "components/CustomButtons/Button.jsx"; //custom styled button
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//classes
import WbSession from "classes/Session.jsx";
//icons
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//colours
import { primaryColor } from "assets/jss/material-dashboard-react.jsx"

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    loginAlign: {
        margin: "0 auto",
        display: "block",
        maxWidth: "600px"
    },
    loginForm: {
        margin: "0 auto",
        display: "block",
        maxWidth: "300px"
    },
    loginFormFooter: {
        width: "100% !important",
        margin: "0 auto !important",
        maxWidth: "330px"
    },
    loginInput: {
        display: "block",
        width: "100%",
        marginTop: "1em"
    },
    loginInputInner: {
        width: "100%"
    },
    button: {

    },
    wrapper: {
        position: 'relative',
        marginTop: "2em",
    },
    buttonProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textRight: {
        textAlign: "right"
    },
    loginError: {
        color: "#f44336"
    }
};

class Login extends React.Component {
    static contextType = WbSession;
    state = {
        user: '',
        pass: '',
        error: false,
        errorMsg: '',
        errorFld: '',
        loading: false, //handle animation for this
        loggedIn: false, //handle route redirect to main page when true
        showPassword: false
    };

    handleChange = (inputName, newValue) => {
        var newState = {error: false};
        newState[inputName] = newValue;
        this.setState(newState);
    };
    handleSubmit = async (event) => {
        event.preventDefault();

        let error = false;
        let errorMsg = '';
        let errorFld = '';
        let input = this.state;
        if( '' === input.user ){
            error = true;
            errorMsg = 'Please enter your username (or email address)';
            errorFld = 'user';
        }
        if( !error && '' === input.pass ){
            error = true;
            errorMsg = 'Please enter your password';
            errorFld = 'pass';
        }

        // validate then send to server
        if( !error ){
            //send to server
            this.tryLogin();
            this.setState({loading:true});
        } else {
            this.setState({error,errorMsg,errorFld,pass:''});
        }
    };
    handleRedirects = () => {
        if (this.state.loggedIn) {
            return <Redirect to='/dashboard' />
        }
    };
    tryLogin = async () => {
        let input = this.state;
        let session = await this.context.login( input.user, input.pass );

        //check results, set loading to false, display errors or set loggedIn to true
        if( session.token ){
            //console.log('Token set - logged in');
            this.setState({loading:false, loggedIn:true});
        } else {
            //console.log('Token not set - error');
            let errorMsg = 'Error connecting to server. Please try again later.';
            let errorFld = 'user';
            if( session.code && session.code.includes('incorrect_password') ){
                errorFld = 'pass';
                errorMsg = 'Incorrect password';
            } else if( session.code && session.code.includes('invalid_username') ){
                errorFld = 'user';
                errorMsg = 'Invalid username';
            } else if( session.code && session.code.includes('invalid_email') ){
                errorFld = 'user';
                errorMsg = 'Invalid username';
            } else if( session.code && session.code.includes('server_error') ){
                errorMsg = 'Error connecting to server';
            } else if( session.code && session.message ){
                errorMsg = session.message;
            }
            //console.log({loading:false, error:true, errorMsg, errorFld});
            //handle error messages
            this.setState({loading:false, error:true, errorMsg, errorFld});
        }
    };

    getError = (input) => {
        let error = false;
        if( this.state.error && (input === this.state.errorFld || '' === this.state.errorFld) ){
            error = true;
        }

        return error;
    };
    getSubtitle = (input) => {
        let desc = '';
        if( this.state.error && (input === this.state.errorFld || (input==='pass' && '' === this.state.errorFld) ) ){
            desc = 'Invalid username or password';
            if( '' !== this.state.errorMsg ){
                desc = this.state.errorMsg;
            }
        }

        return desc;
    };
    updateLoginState = () => {
        let loggedIn = true;
        if( !this.context.isLoggedIn() ){ //if is not logged in? //FIXME
            loggedIn = false;
        }

        if( loggedIn !== this.state.loggedIn ){
            this.setState({ loggedIn: loggedIn });
        }
    };

    componentDidMount() {
        this.updateLoginState();
    }
    componentDidUpdate(e) {
        this.updateLoginState();
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.loginAlign}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Agent Login</h4>
                        <p className={classes.cardCategoryWhite}>Enter your username and password to continue</p>
                    </CardHeader>
                    <CardBody>
                        <form className={classes.loginForm} onSubmit={this.handleSubmit}>
                            {this.handleRedirects()}
                            <TextField
                                label="Username"
                                id="user"
                                error={this.getError('user')}
                                helperText={this.getSubtitle('user')}
                                type="email"
                                className={classes.loginInput}
                                value={this.state.user}
                                fullWidth
                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                InputProps={{
                                    readOnly: this.state.loading,
                                    autoComplete: "username"
                                }}
                            />
                            <FormControl className={classes.loginInput}>
                              <InputLabel htmlFor="pass">Password</InputLabel>
                              <Input
                                id="pass"
                                error={this.getError('pass')}
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={this.state.pass}
                                className={classes.loginInputInner}
                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={()=>{
                                          this.setState({showPassword: !this.state.showPassword});
                                      }}
                                      onMouseDown={(e)=>{e.preventDefault();}}
                                    >
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                              { this.getError('pass') && (
                                  <FormHelperText className={classes.loginError}>{this.getSubtitle('pass')}</FormHelperText>
                              ) }

                            </FormControl>
                            <div className={classes.wrapper}>
                                <Button
                                    className={classes.button}
                                    fullWidth
                                    color="primary"
                                    type="submit"
                                >Login</Button>
                                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </form>
                    </CardBody>
                    <CardFooter chart>
                        <GridContainer className={classes.loginFormFooter}>
                            <GridItem xs={6} sm={6} md={6}>
                                <NavLink
                                    to="/register"
                                >Register</NavLink>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={classes.textRight}>
                                <NavLink
                                    to="/resetpass"
                                >Forgot Password?</NavLink>
                            </GridItem>
                        </GridContainer>
                    </CardFooter>
                </Card>
            </div>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(Login);
