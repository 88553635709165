import React from "react";
import { Redirect, NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Button from "components/CustomButtons/Button.jsx"; //custom styled button
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";

//classes
import WbSession from "classes/Session.jsx";

//colours
import { primaryColor } from "assets/jss/material-dashboard-react.jsx"

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    loginAlign: {
        margin: "0 auto",
        display: "block",
        maxWidth: "600px"
    },
    loginForm: {
        margin: "0 auto",
        display: "block",
        maxWidth: "300px"
    },
    loginFormFooter: {
        width: "100% !important",
        margin: "0 auto !important",
        maxWidth: "330px"
    },
    loginInput: {
        display: "block",
        width: "100%",
        marginTop: "1em"
    },
    button: {
        
    },
    wrapper: {
        position: 'relative',
        marginTop: "2em",
    },
    buttonProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
};


class ResetPass extends React.Component {
    static contextType = WbSession;
    state = {
        user: '',
        error: false,
        errorMsg: '',
        loading: false, //handle animation for this
        reset: false, //handle route redirect to login page when true
        loggedIn: false //handle rediret to dashboard when true
    };
    
    handleRedirects = () => {
        if (this.state.loggedIn) {
            return <Redirect to='/' />
        }
    };
    handleChange = (inputName, newValue) => {
        var newState = {error: false};
        newState[inputName] = newValue;
        this.setState(newState);
    };
    handleSubmit = async (event) => {
        event.preventDefault();
        
        let error = false;
        let errorMsg = '';
        let errorFld = '';
        let input = this.state;
        if( '' === input.user ){
            error = true;
            errorMsg = 'Please enter your username (or email address)';
            errorFld = 'user';
        }
        
        // validate then send to server
        if( !error ){
            //send to server
            this.tryReset();
            this.setState({loading:true});
        } else {
            this.setState({error,errorMsg,errorFld});
        }
    };
    tryReset = async () => {
        let input = this.state;
        let session = null;
        try{
            session = await this.context.sendResetEmail( input.user );
        } catch(err) {
            session = {
                code: 'server_error'
            };
        }
        
        
        //check results, set loading to false, display errors or set loggedIn to true
        if( session.success ){
            this.setState({loading:false, reset:true});
        } else {
            let errorMsg = '';
            let errorFld = '';
            if( session.code && session.code.includes('invalid_username') ){
                errorFld = 'user';
                errorMsg = 'Invalid username or account not found';
            } else if( session.code && session.code.includes('server_error') ){
                errorMsg = 'Error connecting to server';
            } else if( session.code && session.message ){
                errorMsg = session.message;
            }
            // handle error messages
            this.setState({loading:false, error:true, errorMsg, errorFld});
        }
    };
    
    getError = (input) => {
        let error = false;
        if( this.state.error && (input === this.state.errorFld || '' === this.state.errorFld) ){
            error = true;
        }
        
        return error;
    };
    getSubtitle = (input) => {
        let desc = '';
        if( this.state.error && (input === this.state.errorFld || (input==='user' && '' === this.state.errorFld) ) ){
            desc = 'Invalid username or account not found';
            if( '' !== this.state.errorMsg ){
                desc = this.state.errorMsg;
            }
        }
        
        return desc;
    };
    updateLoginState = () => {
        let loggedIn = true;
        if( !this.context.isLoggedIn() ){ //if is not logged in? //FIXME
            loggedIn = false;
        }
        
        if( loggedIn !== this.state.loggedIn ){
            this.setState({ loggedIn: loggedIn });
        }
    };
    
    componentDidMount() {
        this.updateLoginState();
    }
    componentDidUpdate(e) {
        this.updateLoginState();
    }
    
    render() {
        const { classes } = this.props;
        
        return (
            <div className={classes.loginAlign}>
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Password Reset</h4>
                        <p className={classes.cardCategoryWhite}>Enter your username or email address to reset your password</p>
                    </CardHeader>
                    <CardBody>
                        {(this.state.reset) && (
                            <SnackbarContent message={'SUCCESS - Please check your email for a reset link.'} close color="success"/>
                        )}
                        <form className={classes.loginForm} onSubmit={this.handleSubmit}>
                            {this.handleRedirects()}
                            <TextField
                                label="Username / Email Address"
                                id="user"
                                error={this.getError('user')}
                                helperText={this.getSubtitle('user')}
                                type="email"
                                className={classes.loginInput}
                                value={this.state.user}
                                fullWidth
                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                InputProps={{
                                    readOnly: this.state.loading,
                                    autoComplete: "username"
                                }}
                            />
                            <div className={classes.wrapper}>
                                <Button
                                    className={classes.button}
                                    fullWidth
                                    color="primary"
                                    type="submit"
                                >Reset Password</Button>
                                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </form>
                    </CardBody>
                    <CardFooter chart>
                        <GridContainer className={classes.loginFormFooter}>
                        <GridItem xs={6} sm={6} md={6}>
                            <NavLink
                                to="/login"
                            >Return to Login</NavLink>
                        </GridItem>
                        </GridContainer>
                    </CardFooter>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(ResetPass);
