import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//react-ui core components
import {
    Table, TableHead, TableBody, TableRow, TableCell, //tables
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
// @material-ui/icons
import { CloudDownload } from "@material-ui/icons";
//colours
import { defaultFont, grayColor } from "assets/jss/material-dashboard-react.jsx";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";

const customStyles = {
    listTable: {
        marginTop: "1.5em",
        marginBottom: "0",
        overflow: "visible",
        "& thead th": {
            paddingTop: "7px",
            paddingBottom: "7px",
        },
        "& tr>td:first-child, & tr>th:first-child": {
            paddingLeft: "0",
        },
        "& tr>td:last-child, & tr>th:last-child": {
            paddingRight: "0",
        }
    },
    listTableRow: {
        position: "relative",
        borderBottom: "1px solid " + grayColor[5],
        "&:last-child": {
            borderBottom: "none",
        }
    },
    listTableCell: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableCellWide: {
        width: "65%"
    },
    "@media screen and (max-width:959px)": {
        tableActions: {
            display: "inline-block !important",
            width: "50% !important"
        }
    }
};

const styles = { ...tableStyle, ...customStyles };

class Attachments extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }
    
    formatDate = ( dt ) => {
        //let d = new Date( dt ); //NOTE: IOS can't cope with dates like this
        var t = dt.split(/[- :]/);
        let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //format the date portion
        let date = d.getDate();
        let month = months[d.getMonth()];
        if( date < 10 ){
            date = "0"+date;
        }
        let year = d.getFullYear();
        return date + " " + month + " " + year;
    };
    
    formatSize = ( bytes, si ) => {
        var thresh = si ? 1000 : 1024;
        if(Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }
        var units = si
            ? ['kB','MB','GB','TB','PB','EB','ZB','YB']
            : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
        var u = -1;
        do {
            bytes /= thresh;
            ++u;
        } while(Math.abs(bytes) >= thresh && u < units.length - 1);
        return bytes.toFixed(1)+' '+units[u];
    };
    
    fileName = ( name, path ) => {
        if( "" !== name )
            return name;
        
        return path.substring(path.lastIndexOf('/')+1);
    };
    
    componentDidMount() {
        
    }

    render() {
        const {
            classes,
            className,
            files = [],
            baseurl = '',
        } = this.props;
        
        return (
            <Table className={classes.listTable + " " + className}>
                <TableHead>
                    <TableRow>
                        <TableCell key={0}>Name</TableCell>
                        <TableCell key={1}>Size</TableCell>
                        <TableCell key={2}>Date</TableCell>
                        <TableCell key={3}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {files.map( (value, ind) => (
                        <Fragment key={ind}>
                            <TableRow key={ind} className={classes.listTableRow}>
                                <TableCell key={0} className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Name</Typography>
                                    {this.fileName(value.name, value.filename)}
                                </TableCell>
                                <TableCell key={1} className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Size</Typography>
                                    {this.formatSize(value.filesize, true)}
                                </TableCell>
                                <TableCell key={2} className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Date</Typography>
                                    {this.formatDate(value.modified)}
                                </TableCell>
                                <TableCell key={3} className={classes.listTableCell + " " + classes.tableActions}>
                                    <Tooltip
                                        id="tooltip-top"
                                        title="View / Download"
                                        placement="top"
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <IconButton
                                            aria-label="Download"
                                            className={classes.tableActionButton}
                                            href={baseurl+value.filename}
                                            target="_blank"
                                        >
                                            <CloudDownload
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.edit
                                                }
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </Fragment>
                    ))}
                    {(0===files.length) && (
                        <TableRow key="0" className={classes.listTableRow}>
                            <TableCell className={classes.listTableCell +" "+ classes.tableCellWide}>
                                No attachments.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

Attachments.propTypes = {
    className: PropTypes.string,
    files: PropTypes.array.isRequired,
    baseurl: PropTypes.string,
};

Attachments.defaultProps = {
    baseurl: '',
};

export default withStyles(styles)(Attachments);
