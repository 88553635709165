/*
 * Handles popup notifications
 */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Snack from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import snackbarContentStyle from "assets/jss/material-dashboard-react/components/snackbarContentStyle.jsx";

function Snackbar({ ...props }) {
    const {
        classes,
        message,
        color,
        close,
        icon,
        place,
        open,
        rtlActive,
        snackProps,
    } = props;
    var action = [];
    const messageClasses = classNames({
        [classes.iconMessage]: icon !== undefined
    });
    if (close !== undefined) {
        action = [
            <IconButton
                className={classes.iconButton}
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={() => props.closeNotification()}
            >
                <Close className={classes.close} />
            </IconButton>
        ];
    }
    return (
        <Snack
            anchorOrigin={{
                vertical: place.indexOf("t") === -1 ? "bottom" : "top",
                horizontal:
                place.indexOf("l") !== -1
                ? "left"
                : place.indexOf("c") !== -1
                ? "center"
                : "right"
            }}
            open={open}
            message={
                <div>
                    {icon !== undefined ? <props.icon className={classes.icon} /> : null}
                    <span className={messageClasses}>{message}</span>
                </div>
            }
            action={action}
            ContentProps={{
                classes: {
                    root: classes.root + " " + classes[color],
                    message: classes.message,
                    action: classNames({ [classes.actionRTL]: rtlActive })
                }
            }}
            {...snackProps}
        />
    );
}

Snackbar.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.node.isRequired,
    color: PropTypes.oneOf(["info", "success", "warning", "danger", "primary"]),
    close: PropTypes.bool,
    icon: PropTypes.object,
    place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
    open: PropTypes.bool,
    rtlActive: PropTypes.bool,
    snackProps: PropTypes.object,
    closeNotification: PropTypes.func
};

export default withStyles(snackbarContentStyle)(Snackbar);
