import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//Custom components
import GridItem from "components/Grid/GridItem.jsx";
import Attachments from "components/Attachments/Attachments.jsx";
//react-ui core components
import {
    TextField,
    Table, TableHead, TableBody, TableRow, TableCell, //tables
    IconButton,
    Tooltip,
    Typography
} from '@material-ui/core';
import { Redirect } from "react-router-dom";
// @material-ui/icons
import { RemoveRedEye } from "@material-ui/icons";
//colours
import { defaultFont, grayColor } from "assets/jss/material-dashboard-react.jsx";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";

const customStyles = {
    textInput: {
        display: "block",
        width: "100%",
        marginTop: "2em",
        color: "#aaa",
        fontSize: "14px",
    },
    sectionTitle: {
        fontSize: "1.4em",
        marginTop: "1em",
        marginBottom: "-1em",
    },
    listTable: {
        marginTop: "1.5em",
        marginBottom: "0",
        overflow: "visible",
        "& thead th": {
            paddingTop: "7px",
            paddingBottom: "7px",
        },
        "& tr>td:first-child, & tr>th:first-child": {
            paddingLeft: "0",
        },
        "& tr>td:last-child, & tr>th:last-child": {
            paddingRight: "0",
        }
    },
    listTableRow: {
        position: "relative",
        borderBottom: "1px solid " + grayColor[5],
        "&:last-child": {
            borderBottom: "none",
        }
    },
    listTableCell: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableCellWide: {
        width: "65%"
    },
    "@media screen and (max-width:959px)": {
        tableActions: {
            display: "inline-block !important",
            width: "50% !important"
        }
    }
};

const styles = { ...tableStyle, ...customStyles };

class CustomField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadInspection: 0
        };
    }

    formatDate = ( dt ) => {
        if( null === dt || '' === dt )
            return '';
        //let d = new Date( dt ); //NOTE: IOS can't cope with dates like this
        var t = dt.split(/[- :]/);
        let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //format the date portion
        let date = d.getDate();
        let month = months[d.getMonth()];
        if( date < 10 ){
            date = "0"+date;
        }
        let year = d.getFullYear();
        return date + " " + month + " " + year;
    };

    formatDateTime = ( dt ) => {
        if( null === dt || '' === dt )
            return '';
        //let d = new Date( dt ); //NOTE: IOS can't cope with dates like this
        var t = dt.split(/[- :]/);
        let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //format the date portion
        let date = d.getDate();
        let month = months[d.getMonth()];
        if( date < 10 ){
            date = "0"+date;
        }
        let year = d.getFullYear();
        let hr = d.getHours();
        let min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        let ampm = "AM";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "PM";
        }
        return date + " " + month + " " + year + " " + hr + ":" + min + " " + ampm;
    };



    //validate a date string
    testDate = ( dt ) => {
        //let d = new Date( dt ); //NOTE: IOS can't cope with dates like this
        var t = dt.split(/[- :]/);
        if( '0000' === t[0] ){
            return false; //default date is 0000-00-00 00:00:00
        }
        // console.log(t[0]);
        // console.log(t[1]-1);
        // console.log(t[2]);
        // console.log(t[3]);
        // console.log(t[4]);
        // console.log(t[5]);
        let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);

        return !isNaN(d.getDate());
    };

    formatInspectionStatus = (status) => {
        const {
            statuses
        } = this.props;

        return (typeof statuses[status] !== "undefined") ? statuses[status] : '';
    };

    handleRedirects = () => {
        if ( this.state.loadInspection ) {
            let destination = "/inspection/"+this.state.loadInspection;
            return <Redirect to={destination} />
        }
    };
    handleInspectionClick = ( inspectionId ) => {
        this.setState({loadInspection:inspectionId});
    };

    formatInspections = ( inspections ) => {
        const { classes } = this.props;

        return (
            <Table className={classes.listTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>Identifier</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {inspections.map( (value, ind) => (
                        <Fragment key={ind}>
                            {this.testDate(value.inspection_datetime) && (
                                <TableRow key={value.idinspection} className={classes.listTableRow}>
                                    <TableCell className={classes.listTableCell +" "+ classes.tableCellWide}>
                                        <Typography className={classes.mobileHeading}>Identifier</Typography>
                                        {value.identifier}
                                    </TableCell>
                                    <TableCell className={classes.listTableCell}>
                                        <Typography className={classes.mobileHeading}>Date</Typography>
                                        {this.formatDateTime(value.inspection_datetime)}
                                    </TableCell>
                                    <TableCell className={classes.listTableCell}>
                                        <Typography className={classes.mobileHeading}>Status</Typography>
                                        {this.formatInspectionStatus(value.idinspection_status)}
                                    </TableCell>
                                    <TableCell className={classes.listTableCell + " " + classes.tableActions}>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="View Inspection Details"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                aria-label="View"
                                                className={classes.tableActionButton}
                                                onClick={() => {this.handleInspectionClick(value.idinspection)}}
                                            >
                                                <RemoveRedEye
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Fragment>
                    ))}
                    {(0===inspections.length) && (
                        <TableRow key="0" className={classes.listTableRow}>
                            <TableCell className={classes.listTableCell +" "+ classes.tableCellWide}>
                                No inspections scheduled.
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    };

    componentDidMount() {

    }

    render() {
        const {
            classes,
            field = {},
            value = null,
            baseurl = '',
            key = 0,
        } = this.props;

        if( typeof field.hide_empty != "undefined" && field.hide_empty && "" === value ){
            return null;
        }

        let fldDisplay = null;

        switch( field.type ){
            case 'text':
                fldDisplay = (
                    <TextField
                        label={field.name}
                        id={field.label}
                        type="text"
                        className={classes.textInput}
                        value={value}
                        fullWidth
                        InputProps={{
                            readOnly: true
                        }}
                    />
                );
                break;
            case 'date':
                fldDisplay = (
                    <TextField
                        label={field.name}
                        id={field.label}
                        type="text"
                        className={classes.textInput}
                        value={this.formatDate(value)}
                        fullWidth
                        InputLabelProps={{
                            readOnly: true
                        }}
                    />
                );
                break;
            case 'datetime':
                fldDisplay = (
                    <TextField
                        label={field.name}
                        id={field.label}
                        type="text"
                        className={classes.textInput}
                        value={this.formatDateTime(value)}
                        fullWidth
                        InputLabelProps={{
                            readOnly: true
                        }}
                    />
                );
                break;
            case 'bool':
                let val = "NO";
                if( 'on' === value.toLowerCase() || 'yes' === value.toLowerCase() || "1" === value ){
                    val = "YES";
                }
                fldDisplay = (
                    <TextField
                        label={field.name}
                        id={field.label}
                        type="text"
                        className={classes.textInput}
                        value={val}
                        fullWidth
                        InputLabelProps={{
                            readOnly: true
                        }}
                    />
                );
                break;
            case 'title':
                fldDisplay = (
                    <h3 className={classes.sectionTitle}>
                        {field.name}
                    </h3>
                );
                break;

            case 'inspections':
                fldDisplay = this.formatInspections( value );
                break;
            case 'attachments':
                fldDisplay = ( <Attachments files={value} baseurl={baseurl} /> );
                break;
            default:
                break;
        }

        let size = 6;
        if( typeof field.size != "undefined" ){
            size = field.size;
        }
        let size_md = Math.min(12,(size*2));
        let size_sm = Math.min(12,(size*3));

        fldDisplay = (
            <Fragment key={key}>
                {this.handleRedirects()}
                <GridItem xs={size_sm} sm={size_md} md={size}>
                    {fldDisplay}
                </GridItem>
            </Fragment>
        );

        return fldDisplay;
    }
}

CustomField.propTypes = {
    key: PropTypes.number,
    field: PropTypes.object.isRequired,
    value: PropTypes.any, //anything
    statuses: PropTypes.object,
    baseurl: PropTypes.string,
};

export default withStyles(styles)(CustomField);
