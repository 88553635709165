import React from "react";

import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from "react-router-dom";

class Register extends React.Component {
    state = {
        error: false,
        errorMsg: ''
    };
    
    handleRedirect = () => {
        return <Redirect to='/login' />
    };
    
    componentDidMount() {
        window.open("https://rapidreportsact.com.au/agent-registration/",'_blank');
    }
    
    render() {
        return (
            <>
                {this.state.loading && <CircularProgress size={24} />}
                {this.handleRedirect()}
            </>
        );
    }
}

export default Register;
