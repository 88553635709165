/*
 * Handles displaying the inspection booking form. Also allows editing existing bookings
 */
import React from "react";
import PropTypes from "prop-types";

// Custom components
import Snackbar from "components/Snackbar/Snackbar.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Pagination from "components/Pagination/Pagination.jsx";
// @material-ui/icons
import { AddAlert, Work, Archive, Edit, Close, RemoveRedEye, CalendarToday } from "@material-ui/icons";
// @material-ui/core components
import {
    Tabs,
    Tab,
    CircularProgress, //loading spinner
    Table, TableHead, TableBody, TableRow, TableCell, //tables
    IconButton,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Typography
} from '@material-ui/core';
import { Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";

import customTabsStyle from "assets/jss/material-dashboard-react/components/customTabsStyle.jsx";
import tasksStyle from "assets/jss/material-dashboard-react/components/tasksStyle.jsx";
import tableStyle from "assets/jss/material-dashboard-react/components/tableStyle.jsx";

import {
    defaultFont,
    grayColor
} from "assets/jss/material-dashboard-react.jsx";

//classes
import WbSession from "classes/Session.jsx";
 
// core components
let styles = {
    loadingOverlay: {
        position: "relative",
        zIndex: "2",
        backgroundColor: "rgba(255,255,255,1)",
        width: "100%",
        height: "200px",
        top: "0",
        left: "0"
    },
    loader: {
        position: "absolute",
        zIndex: "3",
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    listTable: {
        marginBottom: "0",
        overflow: "visible",
    },
    listTitleRight: {
        textAlign: "right"
    },
    listTableRow: {
        position: "relative",
        borderBottom: "1px solid " + grayColor[5],
        "&:last-child": {
            borderBottom: "none",
        }
    },
    listTableCell: {
        ...defaultFont,
        padding: "8px",
        verticalAlign: "middle",
        border: "none",
        lineHeight: "1.42857143",
        fontSize: "14px",
    },
    tableCellWide: {
        width: "65%"
    },
    cancelLoadWrapper: {
        width: "200px",
        height: "150px",
    },
    listCardFooter: {
        marginTop: "0",
    },
    tableActions: {
        display: "flex",
        border: "none",
        padding: "12px 8px !important",
        verticalAlign: "middle",
        justifyContent: "flex-end"
    },
    stats: {
        color: grayColor[0],
        display: "inline-flex",
        fontSize: "12px",
        lineHeight: "22px",
        "& svg": {
            top: "4px",
            width: "16px",
            height: "16px",
            position: "relative",
            marginRight: "3px",
            marginLeft: "3px"
        },
        "& .fab,& .fas,& .far,& .fal,& .material-icons": {
            top: "4px",
            fontSize: "16px",
            position: "relative",
            marginRight: "3px",
            marginLeft: "3px"
        }
    }
};

styles = { ...customTabsStyle, ...tasksStyle, ...tableStyle, ...styles };

class InspectionListing extends React.Component {
    static contextType = WbSession;
    constructor(props){
        super(props);
        const { jobs } = this.props;
        this.state = {
            error: false,
            errorMsg: '',
            updated: false, //success message
            loading: true, //handle animation for this
            minimal: false,
            
            loadBooking: 0,
            loadJob: 0,
            cancelBooking: 0,
            cancelBookingLoading: 0,
            archive: false, //true if viewing archived bookings
            loadedArchive: false, //true if currently loaded bookings are for the archive
            bookings: [], //array of bookings from server
            jobs: jobs, //array of jobs from InspectionApps - to display below WP inspections
            totalPages: 1,
            currentPage: 1,
        };
    }
    
    tabChange = (event, tab) => {
        this.setState({ archive: (1===tab) });
    };
    
    getBookings = async (archived, page) => {
        const { scope } = this.props;
        
        if( typeof archived == "undefined" ){
            archived = false;
        }
        if( typeof page == "undefined" || 0 === page ){
            page = 1;
        }
        
        this.setState({loading:true});
        
        let response = null;
        try{
            response = await this.context.getBookings( archived, scope, page );
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        let data = response.data;
        
        if( typeof data == "object" && typeof data.code == "undefined" ){
            //all good! loop through each of these to display
            let totalPages = parseInt(response.pages);
            if( 0 === totalPages ){
                totalPages = 1; //Pagination doesn't like 0 pages (ie. no results)
            }
            let newState = {
                error:false,
                loading: false,
                bookings: data,
                archive: archived,
                loadedArchive: archived,
                totalPages: totalPages,
                currentPage: parseInt(response.cur),
            };
            
            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof data == "object" && typeof data.code != "undefined" ){
                errorMsg += " [msg: "+data.code+"]";
            }
            this.setState({error: true, errorMsg});
        }
    };
    
    
    formatDate = ( dt ) => {
        //let d = new Date( dt ); //NOTE: IOS can't cope with dates like this
        var t = dt.split(/[- :]/);
        if( typeof t[5] === 'undefined' ){
            var dts = dt.split(/[ ]/);
            if( typeof dts[1] === 'undefined' ){
                return dt;
            }
            return dts[0];
        }
        let d = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        //format the date portion
        let date = d.getDate();
        if( isNaN(date) ){
            return dt;
        }
        let month = months[d.getMonth()];
        if( date < 10 ){
            date = "0"+date;
        }
        return date + " " + month;
    };
    
    formatTime = ( time ) => {
        let hms = time.split(':');
        let d = new Date();
        d.setHours( hms[0] );
        d.setMinutes( hms[1] );
        
        var hr = d.getHours();
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        var ampm = "AM";
        if( hr > 12 ) {
            hr -= 12;
            ampm = "PM";
        }
        return hr + ":" + min + ampm;; //FIXME: format this better
    };
    
    formatCancelDialog = (value) => {
        const { classes } = this.props;
        if( this.state.cancelBookingLoading===value.id ){
            return (
                <Dialog
                    open={this.state.cancelBooking===value.id}
                >
                    <div className={classes.cancelLoadWrapper}>
                        <CircularProgress size={24} className={classes.loader} />
                    </div>
                </Dialog>
            );
        } else {
            return (
                <Dialog
                    open={this.state.cancelBooking===value.id}
                    onClose={this.closeDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Cancel Booking?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you wish to cancel the booking for {value.time} on {value.date} at {value.address}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.closeDialog} color="primary">
                            No
                        </Button>
                        <Button onClick={() => {this.cancelBooking(value.id)}} color="primary" autoFocus>
                            Yes, Cancel Booking
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
    };
    
    closeDialog = () => {
        this.setState({cancelBooking:0});
    };
    
    cancelBooking = async ( bookingId ) => {
        this.setState({cancelBookingLoading:bookingId});
        
        let response = null;
        try{
            response = await this.context.cancelBooking( bookingId );
        } catch(err) {
            console.log(err);
            response = {
                code: 'server_error'
            };
        }
        
        //check results, set loading to false, display errors or set loggedIn to true
        if( typeof response == "object" && typeof response.id != "undefined" && response.id ){
            this.setState({cancelBookingLoading:false, updated:true, cancelBooking:0});
            this.getBookings( this.state.archive );
        } else {
            let errorMsg = 'Error: failed to cancel booking.';
            if( typeof response == "object" && response.message ){
                errorMsg = response.message;
            }
            
            this.setState({cancelBookingLoading:false, error:true, cancelBooking:0, errorMsg});
        }
    };
    
    onPageChanged = ( data ) => {
        const { currentPage } = data;
        
        if( this.state.currentPage !== currentPage && 0 !== currentPage ){
            this.setState({ currentPage, loading:true });
            //console.log('Pagechange triggered from: '+this.state.currentPage+' to '+currentPage);
            this.getBookings( this.state.archive, currentPage );
        }
    };
    
    formatTabContent = ( type, mergeJobs ) => {
        const { classes } = this.props;
        let isArchive = this.state.archive;
        let isArchiveLoaded = this.state.loadedArchive;
        let key = isArchive ? 1 : 0;
        let inspections = [];
        
        
        if( key !== type ){ //not the current page - leave it blank for now
            return (<div key={type}></div>);
        }
        
        let loader = (<div key={type} className={classes.loadingOverlay}><CircularProgress size={24} className={classes.loader} /></div>);
        if( this.state.loading ){
            return loader;
        }
        
        if( isArchive && !isArchiveLoaded ){ //we're on the future tab but need archived bookings
            //console.log('Tab change - to archive');
            this.getBookings( true );
            return loader;
        } else if( !isArchive && isArchiveLoaded ){
            //console.log('Tab change - to future');
            this.getBookings( false );
            return loader;
        }
        
        let displayed = [];
        
        this.state.bookings.forEach((booking) => {
            let inspRow = {
                date: this.formatDate( booking.inspectiondate ),
                time: this.formatTime( booking.start ),
                address: booking.address,
                suburb: booking.city,
                owner: booking.owner_fname + " " + booking.owner_lname,
                inspid: booking.inspapps_job,
                id: booking.id,
            }
            
            inspections.push(inspRow);
            displayed.push( booking.inspapps_job );
        } );
        
        if( mergeJobs ){
            this.state.jobs.forEach((job) => {
                if( !displayed.includes(job.id) ){
                    //console.log(job);
                    let inspRow = {
                        date: '',
                        time: '',
                        address: job.address,
                        suburb: job.suburb,
                        owner: job.owner,
                        inspid: job.id,
                        id: job.id,
                    }
                    
                    inspections.push(inspRow);
                    displayed.push( job.idjob );
                }
            });
        }
        
        return (
            <div key={type}>
                <Table className={classes.listTable}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Address</TableCell>
                            <TableCell>Suburb</TableCell>
                            <TableCell>Owner</TableCell>
                            <TableCell>Inspection Date</TableCell>
                            <TableCell className={classes.listTitleRight}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inspections.map(value => (
                            <TableRow key={value.id} className={classes.listTableRow}>
                                <TableCell className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Address</Typography>
                                    {value.address}
                                </TableCell>
                                <TableCell className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Suburb</Typography>
                                    {value.suburb}
                                </TableCell>
                                <TableCell className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Owner</Typography>
                                    {value.owner}
                                </TableCell>
                                <TableCell className={classes.listTableCell}>
                                    <Typography className={classes.mobileHeading}>Inspection Date</Typography>
                                    {value.date} {value.time}
                                </TableCell>
                                <TableCell className={classes.tableActions}>
                                    {(''===value.date && ''!==value.inspid&&null!==value.inspid) && (
                                        <Tooltip
                                            id="tooltip-top"
                                            title="View Job"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                aria-label="View"
                                                className={classes.tableActionButton}
                                                onClick={() => {this.handleEditJobClick(value.inspid)}}
                                            >
                                                <RemoveRedEye
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                    {(!this.state.archive && ''!==value.date) && (
                                        <>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="Edit Booking"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                aria-label="Edit"
                                                className={classes.tableActionButton}
                                                onClick={() => {this.handleEditClick(value.id)}}
                                            >
                                                <Edit
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.edit
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                            id="tooltip-top-start"
                                            title="Cancel Booking"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                aria-label="Cancel"
                                                className={classes.tableActionButton}
                                                onClick={() => {this.handleCancelClick(value.id)}}
                                            >
                                                <Close
                                                    id={"cancel_"+value.id}
                                                    className={
                                                        classes.tableActionButtonIcon + " " + classes.close
                                                    }
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        {this.formatCancelDialog( value )}
                                        </>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        {(0===inspections.length) && (
                            <TableRow key="0" className={classes.listTableRow}>
                                <TableCell className={classes.listTableCell +" "+ classes.tableCellWide}>
                                    No {(this.state.archive)?'archived':'pending'} bookings.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <Pagination
                    numPages={this.state.totalPages}
                    pageNeighbours={1}
                    onPageChanged={this.onPageChanged}
                    currPage={this.state.currentPage}
                />
            </div>
        );
    };
    
    handleRedirects = () => {
        if ( this.state.loadBooking ) {
            let destination = "/book/"+this.state.loadBooking;
            return <Redirect to={destination} />
        } else if ( this.state.loadJob ){
            let destination = "/job/"+this.state.loadJob;
            return <Redirect to={destination} />
        }
    };
    handleEditClick = ( bookingId ) => {
        this.setState({loadBooking:bookingId});
    };
    handleEditJobClick = ( jobId ) => {
        this.setState({loadJob:jobId});
    };
    handleCancelClick = ( bookingId ) => {
        this.setState({cancelBooking:bookingId});
    };
    
    resize = () => {
        let mobile = true;
        if( window.innerWidth >= 700 ){
            mobile = false;
        }
        if( this.state.minimal !== mobile ){
            this.setState({minimal: mobile});
        }
    };
    
    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        
        //send AJAX request to server to get all future bookings
        this.getBookings( false );
    }
    render() {
        const { classes, noHeader } = this.props;
        return (
            <>
                {this.handleRedirects()}
                <Snackbar
                  place="tc"
                  color="danger"
                  icon={AddAlert}
                  message={this.state.errorMsg}
                  open={(this.state.error)}
                  closeNotification={() => this.setState({ error: false })}
                  close
                />
                <Snackbar
                  place="tc"
                  color="success"
                  icon={AddAlert}
                  message="The booking was successfully cancelled!"
                  open={(this.state.updated)}
                  closeNotification={() => this.setState({ updated: false })}
                  close
                />
                
                {!noHeader && (
                    <Card>
                        <CardHeader color="primary">
                            <div className={classes.cardTitle}>Inspection requests: </div>
                            <Tabs
                                value={((this.state.archive)?1:0)}
                                onChange={this.tabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                classes={{
                                    root: classes.tabsRoot,
                                    indicator: classes.displayNone,
                                    scrollButtons: classes.displayNone
                                }}
                            >
                                <Tab
                                    classes={{
                                        root: classes.tabRootButton,
                                        selected: classes.tabSelected,
                                        wrapper: classes.tabWrapper
                                    }}
                                    key={0}
                                    label="Future"
                                    icon={<Work />}
                                />
                                <Tab
                                    classes={{
                                        root: classes.tabRootButton,
                                        selected: classes.tabSelected,
                                        wrapper: classes.tabWrapper
                                    }}
                                    key={1}
                                    label="Archive"
                                    icon={<Archive />}
                                />
                            </Tabs>
                        </CardHeader>
                        <CardBody>
                            {this.formatTabContent( 0, false )}
                            {this.formatTabContent( 1, false )}
                        </CardBody>
                        <CardFooter stats className={classes.listCardFooter}>
                            <div className={classes.stats}>
                                <CalendarToday />
                                Your scheduled inspections.
                            </div>
                        </CardFooter>
                    </Card>
                )}
                {noHeader && (
                    <>
                    {this.formatTabContent( 0, true )}
                    </>
                )}
            </>
        );
    }
}

InspectionListing.propTypes = {
    classes: PropTypes.object,
    noHeader: PropTypes.bool,
    jobs: PropTypes.array,
    scope: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number
    ]),
};

InspectionListing.defaultProps = {
    noHeader: false,
    jobs: [],
    scope: null
};

export default withStyles(styles)(InspectionListing);
