import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import {
    TextField,
    FormHelperText,
    CircularProgress, //loading spinner
    Switch,
    FormControl,
    FormControlLabel
} from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";

//classes
import WbSession from "classes/Session.jsx";
//colours
import { primaryColor } from "assets/jss/material-dashboard-react.jsx"

const styles = {
    textInput: {
        display: "block",
        width: "100%",
        marginTop: "2em",
        color: "#aaa",
        fontSize: "14px",
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    loadingWrapper: {
        position: 'absolute',
        top: 0,
        left:0,
        width: "100%",
        height: '100%',
        background: "rgba(255,255,255,0.5)",
        zIndex: 3
    },
    loadingProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    buttonWrapper: {
        width: "100%",
        position: 'relative',
        marginTop: "2em",
    },
    buttonProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    cardImage: {
        width: "96px",
        height: "96px",
        maxWidth: "100%",
        background: "#ffffff",
        paddingRight: "35px"
    },
    cardSubTitle: {
        fontSize: "0.9em",
        margin: "0",
    },
    toggleControl: {
        marginTop: "10px",
        width: "100%",
        color: "#aaa",
    },
};

class UserProfile extends React.Component {
    static contextType = WbSession;
    constructor(props){
        super(props);
        this.state = {
            error: false,
            errorMsg: '',
            updated: false, //success message
            loading: true, //handle animation for this
            saving: false,
            profile: {
                first_name: "",
                last_name: "",
                email: "",
                avatar_urls: {
                    24: null,
                    48: null,
                    96: null
                },
                billing_company: "",
                billing_address_1: "",
                billing_address_2: "",
                billing_city: "",
                billing_postcode: "",
                billing_email: "",
                billing_phone: "",
                description: "",
                billing_abn: "",
                inspapps_emails_disabled: "",
                inspapps_push_disabled: "",
            }
        };
    }
    
    handleChange = (inputName, newValue) => {
        var newState = {error: false};
        newState.profile = this.state.profile;
        newState.profile[inputName] = newValue;
        this.setState(newState);
    };
    
    getError = (input) => {
        let error = false;
        if( this.state.error && (input === this.state.errorFld || '' === this.state.errorFld) ){
            error = true;
        }
        
        return error;
    };
    getSubtitle = (input, wrap) => {
        let desc = '';
        if( this.state.error && (input === this.state.errorFld) ){
            desc = 'Invalid input';
            if( '' !== this.state.errorMsg ){
                desc = this.state.errorMsg;
            }
        }
        
        if( wrap ){
            desc = (<FormHelperText error>{desc}</FormHelperText>);
        }
        
        return desc;
    };
    
    handleSubmit = (event) => {
        // validate then send to server
        let error = false;
        let errorMsg = '';
        let errorFld = '';
        let input = this.state.profile;
        let errorFields = [
            {fld: 'first_name', err: 'Please enter your First Name'},
            {fld: 'last_name', err: 'Please enter your Last Name'},
            {fld: 'billing_company', err: 'Please enter your Company Name'},
            {fld: 'billing_address_1', err: 'Please enter your Office Address'},
            {fld: 'billing_city', err: 'Please enter your City'},
            {fld: 'billing_postcode', err: 'Please enter your Postcode'},
            {fld: 'billing_email', err: 'Please enter a Contact Email address'},
            {fld: 'billing_phone', err: 'Please enter a Contact Phone number'}
        //    {fld: 'billing_abn', err: 'Please enter your company ABN'}
        ];
        errorFields.reverse();
        errorFields.forEach(function( field ){
            let key = field.fld;
            let msg = field.err;
            if( '' === input[key] ){
                error = true;
                errorMsg = msg;
                errorFld = key;
            }
        });
        
        // validate then send to server
        if( !error ){
            //send to server
            this.sendUpdate();
            this.setState({saving:true});
        } else {
            //console.log({error:error,errorMsg,errorFld});
            this.setState({error:error,errorMsg,errorFld});
        }
        
        event.preventDefault();
    };
    
    sendUpdate = async () => {
        let input = this.state.profile;
        let response = null;
        try{
            response = await this.context.updateProfile( input );
        } catch(err) {
            console.log(err);
            response = {
                code: 'server_error'
            };
        }
        
        //check results, set loading to false, display errors or set loggedIn to true
        if( typeof response == "object" && typeof response.id != "undefined" && response.id ){
            this.setState({saving:false, updated:true});
            //TODO: update profile fields with server-validated content
        } else {
            let errorMsg = '';
            let errorFld = '';
            if( typeof response == "object" && response.message ){
                errorMsg = response.message;
            }
            
            this.setState({saving:false, error:true, errorMsg, errorFld});
        }
    };
    
    getProfile = async () => {
        this.setState({loading:true});
        
        let response = null;
        try{
            response = await this.context.getProfile();
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }
        
        let data = response;
        
        if( typeof data == "object" && typeof data.code == "undefined" ){
            //all good! loop through each of these to display
            let newProfile = this.state.profile;
            for (var property in data) {
                if (data.hasOwnProperty(property)) {
                    newProfile[property] = data[property];
                }
            }
            let newState = {
                error:false,
                loading: false,
                profile: newProfile,
            };
            
            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof data == "object" &&  typeof data.code != "undefined" ){
                errorMsg += " [msg: "+data.code+"]";
            }
            this.setState({loading: false, error: true, errorMsg});
        }
    };
    
    componentDidMount(){
        //send AJAX request to server to get saved info
        this.getProfile( false );
    }
    
    
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Snackbar
                  place="tc"
                  color="danger"
                  icon={AddAlert}
                  message={this.state.errorMsg}
                  open={(this.state.error && '' === this.state.errorFld)}
                  closeNotification={() => this.setState({ error: false })}
                  close
                />
                <Snackbar
                  place="tc"
                  color="success"
                  icon={AddAlert}
                  message="Your profile was successfully updated!"
                  open={(this.state.updated)}
                  closeNotification={() => this.setState({ updated: false })}
                  close
                />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        <form onSubmit={this.handleSubmit}>
                            <Card>
                                <CardHeader color="primary">
                                    <h4 className={classes.cardTitleWhite}>Edit Profile</h4>
                                    <p className={classes.cardCategoryWhite}>Complete your profile</p>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="First Name"
                                                id="first_name"
                                                error={this.getError('first_name')}
                                                helperText={this.getSubtitle('first_name')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['first_name']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="Last Name"
                                                id="last_name"
                                                error={this.getError('last_name')}
                                                helperText={this.getSubtitle('last_name')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['last_name']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="Company"
                                                id="billing_company"
                                                error={this.getError('billing_company')}
                                                helperText={this.getSubtitle('billing_company')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['billing_company']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            {
                                            /*<TextField
                                                label="ABN"
                                                id="billing_abn"
                                                error={this.getError('billing_abn')}
                                                helperText={this.getSubtitle('billing_abn')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['billing_abn']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />*/
                                            }
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="Office Location"
                                                id="billing_address_1"
                                                error={this.getError('billing_address_1')}
                                                helperText={this.getSubtitle('billing_address_1')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['billing_address_1']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="Address (line 2)"
                                                id="billing_address_2"
                                                error={this.getError('billing_address_2')}
                                                helperText={this.getSubtitle('billing_address_2')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['billing_address_2']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="City"
                                                id="billing_city"
                                                error={this.getError('billing_city')}
                                                helperText={this.getSubtitle('billing_city')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['billing_city']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="Postcode"
                                                id="billing_postcode"
                                                error={this.getError('billing_postcode')}
                                                helperText={this.getSubtitle('billing_postcode')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['billing_postcode']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="Contact Email"
                                                id="billing_email"
                                                error={this.getError('billing_email')}
                                                helperText={this.getSubtitle('billing_email')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['billing_email']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <TextField
                                                label="Contact Phone"
                                                id="billing_phone"
                                                error={this.getError('billing_phone')}
                                                helperText={this.getSubtitle('billing_phone')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['billing_phone']}
                                                fullWidth
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <TextField
                                                label="About"
                                                id="description"
                                                error={this.getError('description')}
                                                helperText={this.getSubtitle('description')}
                                                type="text"
                                                className={classes.textInput}
                                                value={this.state.profile['description']}
                                                fullWidth
                                                multiline
                                                onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                                InputProps={{
                                                    readOnly: this.state.loading
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <FormControl className={classes.toggleControl}>
                                                <FormControlLabel
                                                    label="Push notifications"
                                                    className={classes.inputControlLabel}
                                                    control={
                                                        <Switch
                                                            checked={('on' !== this.state.profile['inspapps_push_disabled'])}
                                                            onChange={(event) => this.handleChange("inspapps_push_disabled", (event.target.checked?'':'on'))}
                                                            disabled={this.state.loading}
                                                            value="inspapps_push_disabled"
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                                
                                                <FormHelperText>{('on'!==this.state.profile['inspapps_push_disabled']) ? "App notifications enabled" : "App notifications disabled"}</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6}>
                                            <FormControl className={classes.toggleControl}>
                                                <FormControlLabel
                                                    label="Email notifications"
                                                    className={classes.inputControlLabel}
                                                    control={
                                                        <Switch
                                                            checked={('on' !== this.state.profile['inspapps_emails_disabled'])}
                                                            onChange={(event) => this.handleChange("inspapps_emails_disabled", (event.target.checked?'':'on'))}
                                                            disabled={this.state.loading}
                                                            value="inspapps_emails_disabled"
                                                            color="primary"
                                                        />
                                                    }
                                                />
                                                
                                                <FormHelperText>{('on'!==this.state.profile['inspapps_emails_disabled']) ? "Email notifications enabled" : "Email notifications disabled"}</FormHelperText>
                                            </FormControl>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <div className={classes.buttonWrapper}>
                                        <Button
                                            className={classes.button}
                                            fullWidth
                                            color="primary"
                                            type="submit"
                                            disabled={this.state.saving}
                                        >Update Profile</Button>
                                        {this.state.saving && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </CardFooter>
                            </Card>
                        </form>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card profile>
                            <CardAvatar profile>
                                <img className={classes.cardImage} src={this.state.profile.avatar_urls[96]} alt={this.state.profile['first_name'] + " " + this.state.profile['last_name']} />
                            </CardAvatar>
                            <CardBody profile>
                                <h6 className={classes.cardCategory}>{this.state.profile['billing_company']}</h6>
                                <h4 className={classes.cardTitle}>{this.state.profile['first_name'] + " " + this.state.profile['last_name']}</h4>
                                <h5 className={classes.cardSubTitle}>{this.state.profile['email']}</h5>
                                <p className={classes.description}>
                                    {this.state.profile['description']}
                                </p>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                {this.state.loading && (
                    <div className={classes.loadingWrapper}>
                        <CircularProgress size={24} className={classes.loadingProgress} />
                    </div>
                )}
            </div>
        );
    }
}

UserProfile.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(UserProfile);
