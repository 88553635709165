import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
//classes
import WbSession from "classes/Session.jsx";

import routes from "routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebarbck.jpg";
import logo from "assets/img/logo.png";

let ps;

class Dashboard extends React.Component {
    static contextType = WbSession;
    state = {
        image: image,
        color: "blue",
        hasImage: true,
        fixedClasses: "dropdown show",
        mobileOpen: false,
        isMobile: false,
        loggedIn: true,
        defaultPage: "dashboard",
        
        sessionError: false,
        sessionErrorMsg: "",
    };
    mainPanel = React.createRef();
    handleImageClick = image => {
        this.setState({ image: image });
    };
    handleColorClick = color => {
        this.setState({ color: color });
    };
    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
            this.setState({ fixedClasses: "dropdown show" });
        } else {
            this.setState({ fixedClasses: "dropdown" });
        }
    };
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ isMobile: false, mobileOpen: false });
        } else {
            this.setState({ isMobile: true });
        }
    };
    updateLoginState = () => {
        let session = this.context;
        let defaultPage = "/dashboard";
        let loggedIn = true;
        if( !session.isLoggedIn() ){ //if is not logged in? //FIXME
            defaultPage = "/login";
            loggedIn = false;
            //console.log('No session');
        } else {
            //console.log('Checking session');
            this.checkSessionActive( session ); //Ajax call to verify session
        }
                
        if( loggedIn !== this.state.loggedIn || defaultPage !== this.state.defaultPage ){
            //console.log('Updating state: '+defaultPage+' '+loggedIn);
            this.setState({ defaultPage:defaultPage, loggedIn:loggedIn, sessionError:false });
        }
    };
    checkSessionActive = async ( wbSession ) => {
        let active = null;
        try{
            active = await wbSession.validate_session();
        } catch(err) {
            active = true; //probably should display an error or something here instead
        }
        
        if( !active ){
            this.setState({ defaultPage: "/login", loggedIn: false });
        }
    };
    handleSessionError = ( errMsg ) => {
        this.setState({ sessionError: true, sessionErrorMsg: errMsg });
    };
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.mainPanel.current);
        }
        window.addEventListener("resize", this.resizeFunction);
        this.resizeFunction(); //check for mobile
        
        this.context.passControllerErrorFunction( this.handleSessionError );
        
        this.updateLoginState();
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.mainPanel.current.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
            this.updateLoginState();
        }
    }
    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
        window.removeEventListener("resize", this.resizeFunction);
    }
    render() {
        const { classes, ...rest } = this.props;
        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={routes}
                    logo={logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    mobile={this.state.isMobile}
                    {...rest}
                />
                <div className={classes.mainPanel} ref={this.mainPanel}>
                    <Navbar
                        routes={routes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    <div className={classes.content}>
                        <div className={classes.container}>
                            <Switch>
                                {routes.map((prop, key) => {
                                    if (prop.layout === "/" && (this.state.loggedIn || true === prop.noUser)) {
                                        let supportIds = (typeof prop.supportIds != "undefined")?prop.supportIds:false;
                                        let addPath = "";
                                        if( supportIds ){
                                            addPath += "/:id";
                                        }
                                        return (
                                            <Route
                                                path={prop.layout + prop.path + addPath}
                                                component={prop.component}
                                                key={key}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                                <Redirect from="/" to={this.state.defaultPage} />
                            </Switch>
                        </div>
                    </div>
                    <Footer />
                </div>
                <Snackbar
                    place="bl"
                    color="danger"
                    icon={AddAlert}
                    message={
                        <div>
                            {"ERROR: You have been logged out. "}
                            <u>{"Click here"}</u>
                            {" to log in again."}
                            <pre>{this.state.sessionErrorMsg}</pre>
                        </div>
                    }
                    open={this.state.sessionError}
                    snackProps={{
                        onClick: () => {this.context.logout();this.setState({sessionError:false});this.updateLoginState();}
                    }}
                />
            </div>
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
