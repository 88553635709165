import React, { Component } from 'react'
import PropTypes from 'prop-types';
// @material-ui/icons
import { CloudUpload } from "@material-ui/icons";

import withStyles from "@material-ui/core/styles/withStyles";

let styles = {
    dropzone: {
        height: "130px",
        width: "100%",
        backgroundColor: "#fff",
        border: "2px dashed rgb(187, 186, 186)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        fontSize: "16px",
        clear: "both",
    },
    highlight: {
        backgroundColor: "rgb(188, 185, 236)",
    },
    icon: {
        opacity: "0.3",
        height: "64px",
        width: "64px",
    },
    fileInput: {
        display: "none",
    }
};

class Dropzone extends Component {
	constructor(props) {
		super(props)
		this.state = { hightlight: false }
		this.fileInputRef = React.createRef()

		this.openFileDialog = this.openFileDialog.bind(this)
		this.onFilesAdded = this.onFilesAdded.bind(this)
		this.onDragOver = this.onDragOver.bind(this)
		this.onDragLeave = this.onDragLeave.bind(this)
		this.onDrop = this.onDrop.bind(this)
	}

	openFileDialog() {
		if (this.props.disabled) return
		this.fileInputRef.current.click()
	}

	onFilesAdded(evt) {
		if (this.props.disabled) return
		const files = evt.target.files
		if (this.props.onFilesAdded) {
			const array = this.fileListToArray(files)
			this.props.onFilesAdded(array)
		}
	}

	onDragOver(evt) {
		evt.preventDefault()

		if (this.props.disabled) return

		this.setState({ hightlight: true })
	}

	onDragLeave() {
		this.setState({ hightlight: false })
	}

	onDrop(event) {
		event.preventDefault()

		if (this.props.disabled) return

		const files = event.dataTransfer.files
		if (this.props.onFilesAdded) {
			const array = this.fileListToArray(files)
			this.props.onFilesAdded(array)
		}
		this.setState({ hightlight: false })
	}

	fileListToArray(list) {
		const array = []
		for (var i = 0; i < list.length; i++) {
			array.push(list.item(i))
		}
		return array
	}

	render() {
        const { classes } = this.props;
        
		return (
			<div
				className={(this.state.hightlight) ? classes.dropzone + " " + classes.highlight : classes.dropzone}
				onDragOver={this.onDragOver}
				onDragLeave={this.onDragLeave}
				onDrop={this.onDrop}
				onClick={this.openFileDialog}
				style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
			>
				<input
					ref={this.fileInputRef}
					className={classes.fileInput}
					type="file"
					multiple
					onChange={this.onFilesAdded}
				/>
				<CloudUpload />
				<span>Upload File(s)</span>
			</div>
		)
	}
}

Dropzone.propTypes = {
    onFilesAdded: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

Dropzone.defaultProps = {
    disabled: false,
};

export default withStyles(styles)(Dropzone);
