import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
};

let styles = {
    pagination: {
        marginTop: "15px",
        marginBottom: 0,
        boxShadow: "0 1px 2px 0 rgba(34,36,38,.15)",
        borderRadius: "3px",
        border: "1px solid rgba(34,36,38,.15)",
        display: "inline-flex",
        paddingLeft: 0,
        listStyle: "none",
        "&after": {
            content: " ",
            display: "table",
            clear: "both",
        }
    },
    pageLink: {
        display: "block",
        padding: "13px 16px",
        textAlign: "center",
        boxShadow: "none !important",
        color: "rgba(0,0,0,.87)",
        fontWeight: "400",
        fontSize: "1rem",
        appearance: "none",
        WebkitAppearance: "none",
        border: "none",
        position: "relative",
        background: "transparent",
        lineHeight: "1",
        cursor: "pointer",
        "&:hover,&:focus": {
            color: "rgba(0,0,0,.95) !important",
            backgroundColor: "rgba(0,0,0,.05) !important",
        },
        "&before": {
            position: "absolute",
            content: '',
            top: 0,
            right: 0,
            height: "100%",
            width: "1px",
            background: "rgba(34,36,38,.1)",
        }
    },
    pageLinkActive: {
        color: "rgba(0,0,0,.95) !important",
        backgroundColor: "rgba(0,0,0,.05) !important",
    }
};

class Pagination extends Component {
    constructor(props) {
        super(props);
        const { numPages = 1, pageNeighbours = 0, currPage = 1 } = props;

        this.pageNeighbours =
            typeof pageNeighbours === "number"
                ? Math.max(0, Math.min(pageNeighbours, 2))
                : 0;
        
        this.totalPages = numPages;

        this.state = { currentPage: currPage };
    }

    componentDidMount() {
        this.gotoPage( this.state.currentPage );
    }

    gotoPage = ( page ) => {
        const { onPageChanged = f => f } = this.props;

        const currentPage = Math.max(0, Math.min(page, this.totalPages));

        const paginationData = {
            currentPage
        };

        this.setState({ currentPage }, () => onPageChanged(paginationData));
    };

    handleClick = (page, evt) => {
        evt.preventDefault();
        this.gotoPage(page);
    };

    handleMoveLeft = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage - this.pageNeighbours * 2 - 1);
    };

    handleMoveRight = evt => {
        evt.preventDefault();
        this.gotoPage(this.state.currentPage + this.pageNeighbours * 2 + 1);
    };

    fetchPageNumbers = () => {
        const totalPages = this.totalPages;
        const currentPage = this.state.currentPage;
        const pageNeighbours = this.pageNeighbours;

        const totalNumbers = this.pageNeighbours * 2 + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            let pages = [];

            const leftBound = currentPage - pageNeighbours;
            const rightBound = currentPage + pageNeighbours;
            const beforeLastPage = totalPages - 1;

            const startPage = leftBound > 2 ? leftBound : 2;
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage;

            pages = range(startPage, endPage);

            const pagesCount = pages.length;
            const singleSpillOffset = totalNumbers - pagesCount - 1;

            const leftSpill = startPage > 2;
            const rightSpill = endPage < beforeLastPage;

            const leftSpillPage = LEFT_PAGE;
            const rightSpillPage = RIGHT_PAGE;

            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1);
                pages = [leftSpillPage, ...extraPages, ...pages];
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset);
                pages = [...pages, ...extraPages, rightSpillPage];
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage];
            }

            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    render() {
        if (this.totalPages === 1) return null;
        
        const { classes } = this.props;

        const { currentPage } = this.state;
        const pages = this.fetchPageNumbers();

        return (
            <Fragment>
                <nav aria-label="Pagination">
                    <ul className={classes.pagination}>
                        {pages.map((page, index) => {
                            if (page === LEFT_PAGE)
                                return (
                                    <li key={index} className="page-item">
                                        <button
                                            className={classes.pageLink}
                                            aria-label="Previous"
                                            onClick={this.handleMoveLeft}
                                        >
                                            <span aria-hidden="true">&laquo;</span>
                                            <span className="sr-only">Previous</span>
                                        </button>
                                    </li>
                                );

                            if (page === RIGHT_PAGE)
                                return (
                                    <li key={index} className="page-item">
                                        <button
                                            className={classes.pageLink}
                                            aria-label="Next"
                                            onClick={this.handleMoveRight}
                                        >
                                            <span aria-hidden="true">&raquo;</span>
                                            <span className="sr-only">Next</span>
                                        </button>
                                    </li>
                                );

                            return (
                                <li
                                    key={index}
                                    className={`page-item${
                                        currentPage === page ? " active" : ""
                                    }`}
                                >
                                    <button
                                        className={classes.pageLink + " " + ((currentPage === page) ? classes.pageLinkActive : "")}
                                        onClick={e => this.handleClick(page, e)}
                                    >
                                        {page}
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </Fragment>
        );
    }
}

Pagination.propTypes = {
    numPages: PropTypes.number.isRequired,
    currPage: PropTypes.number.isRequired,
    pageNeighbours: PropTypes.number,
    onPageChanged: PropTypes.func
};

export default withStyles(styles)(Pagination);
