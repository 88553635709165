import React from "react";

import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from "react-router-dom";
//classes
import WbSession from "classes/Session.jsx";

class Logout extends React.Component {
    static contextType = WbSession;
    state = {
        error: false,
        errorMsg: '',
        loading: true, //handle animation for this
        loggedOut: false, //handle route redirect to main page when true
    };
    
    handleRedirects = () => {
        if (this.state.loggedOut) {
            return <Redirect to='/login' />
        }
    };
    doLogout = async () => {
        this.context.logout();
        this.setState({ loggedOut: true });
    };
    
    updateLoginState = () => {
        let loggedIn = true;
        if( !this.context.isLoggedIn() ){ //if is not logged in? //FIXME
            loggedIn = false;
        }
        
        if( !loggedIn ){
            this.setState({ loggedOut: true });
        } else {
            this.doLogout();
        }
    };
    
    componentDidMount() {
        this.updateLoginState();
    }
    
    render() {
        return (
            <>
                {this.state.loading && <CircularProgress size={24} />}
                {this.handleRedirects()}
            </>
        );
    }
}

export default Logout;
