/*
 * Handles the sidebar and naviation.
 *  NOTE: links defined in /routes.js
 */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

const Sidebar = ({ ...props }) => {
    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return window.location.href.indexOf(routeName) > -1 ? true : false;
    }
    const { classes, color, logo, image, routes, mobile } = props;
    var links = (
        <List className={classes.list}>
            {routes.map((prop, key) => { //TODO: support sub-menu items
                var activePro = " ";
                var listItemClasses;
                if (prop.path === "/upgrade-to-pro") {
                    activePro = classes.activePro + " ";
                    listItemClasses = classNames({
                        [" " + classes[color]]: true
                    });
                } else {
                    listItemClasses = classNames({
                        [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                    });
                }
                const whiteFontClasses = classNames({
                    [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
                });
                if( prop.inMenu || (mobile && prop.inMenuMobile) ){
                    let supportIds = (typeof prop.supportIds != "undefined")?prop.supportIds:false;
                    let addPath = "";
                    if( supportIds ){
                        addPath += "/0";
                    }
                    return (
                        <NavLink
                            to={prop.layout + prop.path + addPath}
                            className={activePro + classes.item}
                            activeClassName="active"
                            key={key}
                        >
                            <ListItem button className={classes.itemLink + listItemClasses}>
                                {typeof prop.icon === "string" ? (
                                    <Icon
                                        className={classNames(classes.itemIcon, whiteFontClasses)}
                                    >
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(classes.itemIcon, whiteFontClasses)}
                                    />
                                )}
                                <ListItemText
                                    primary={prop.name}
                                    className={classNames(classes.itemText, whiteFontClasses)}
                                    disableTypography={true}
                                />
                            </ListItem>
                        </NavLink>
                    );
                } else {
                    return null;
                }
            })}
        </List>
    );
    var brand = (
        <div className={classes.logo}>
          <a
            href="/dashboard"
            className={classes.logoLink}
          >
            <div className={classes.logoImage}>
              <img src={logo} alt="logo" className={classes.img} />
            </div>
          </a>
        </div>
    );
    return (
        <div>
          <Hidden mdUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={props.open}
              classes={{
                paper: classes.drawerPaper
              }}
              onClose={props.handleDrawerToggle}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {brand}
              <div className={classes.sidebarWrapper}>
                {links}
              </div>
              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{ backgroundImage: "url(" + image + ")" }}
                />
              ) : null}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              anchor="left"
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper
              }}
            >
              {brand}
              <div className={classes.sidebarWrapper}>{links}</div>
              {image !== undefined ? (
                <div
                  className={classes.background}
                  style={{ backgroundImage: "url(" + image + ")" }}
                />
              ) : null}
            </Drawer>
          </Hidden>
        </div>
    );
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    handleDrawerToggle: PropTypes.func,
    bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
    logo: PropTypes.string,
    image: PropTypes.string,
    logoText: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    open: PropTypes.bool,
    mobile: PropTypes.bool,
};

export default withStyles(sidebarStyle)(Sidebar);
