/*
 * Handles displaying the inspection booking form. Also allows editing existing bookings
 */
import React from "react";
import PropTypes from "prop-types";

// Custom components
//import Tooltip from "@material-ui/core/Tooltip"; //maybe later
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Comments from "components/Comments/Comments.jsx";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import FileUploader from "components/FileUploader/FileUploader.jsx";
import Attachments from "components/Attachments/Attachments.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {
    FormControl,
    InputLabel,
    TextField,
    Select,
    FormControlLabel,
    Switch,
    MenuItem, //each select option
    FormHelperText, //description below input fields
    CircularProgress, //loading spinner
} from '@material-ui/core';
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert";
// @material-ui/date-fns
import DateFnsUtils from '@date-io/date-fns';
// @material-ui/pickers
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

//classes
import WbSession from "classes/Session.jsx";
//colours
import { primaryColor } from "assets/jss/material-dashboard-react.jsx"

// core components
const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    sectionTitle: {
        fontSize: "1.4em",
        marginTop: "3em",
        marginBottom: "0",
        "& + .MuiGrid-root .MuiFormControl-root": {
            marginTop: "0",
        },
        "&:first-child": {
            marginTop: "0",
        }
    },
    textInput: {
        display: "block",
        width: "100%",
        marginTop: "1.6em",
        color: "#aaa",
        fontSize: "14px",
    },
    datePicker: {
        marginTop: "1em",
        display: "block",
        color: "#aaa",
        fontSize: "16px",
    },
    selectControl: {
        marginTop: "1.6em",
        width: "100%",
        color: "#aaa",
    },
    inputControlLabel: {
        fontSize: "16px",
    },
    toggleControl: {
        marginTop: "10px",
        width: "100%",
        color: "#aaa",
    },
    dateWrapper: {
        position: "relative",
        marginTop: "1.6em",
    },
    dateOverlay: {
        position: "absolute",
        zIndex: "2",
        backgroundColor: "rgba(255,255,255,0.5)",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0"
    },
    dateLoader: {
        position: "absolute",
        zIndex: "3",
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    tenantInput: {
        marginTop: "0 !important",
    },
    attachmentsListing: {
        marginTop: "0 !important"
    },
    smallButton: {
        paddingTop: "4px",
        paddingBottom: "4px",
    },
    buttonWrapper: {
        position: 'relative',
        marginTop: "2em",
    },
    buttonProgress: {
        color: primaryColor,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
};

class BookInspection extends React.Component {
    static contextType = WbSession;
    constructor(props){
        super(props);

        this.logging = true;

        const { match } = props;
        let bookingId = match.params.id;

        this.state = {
            post_id: (bookingId || 0),
            fields: {
                owner_fname: '',
                owner_lname: '',
                owner_email: '',
                owner_phone: '',

                address: '',
                address2: '',
                city: '',
                state: 'ACT',
                postcode: '',
                block: '',
                section: '',

                insptype: '1',
                date: '',
                time: '',

                has_tenant: '',
                tenant_names: '',
                tenant_email: '',
                tenant_phone: '',

                meet_inspector: 'on',
                contact_name: '',
                contact_phone: '',
                comment: '',

                files: [],
            },

            error: false,
            errorMsg: '',
            errorFld: '',
            loading: false, //handle animation for this
            filesLoading: false,
            contactLoading: false,
            updated: false, //success message
            readOnly: false,

            //from server
            openDate: null, //start date on Calendar
            closeDate: null, //last avalilable date on calendar
            unAvailableDays: [], //weekday numbers which are unavaliable (ie. Sat/Sunday)
            unAvailableDates: [], //dates which are booked out or unavailable
            unAvailableTimes: [], //array of dates & times which are already booked
            inspectionTypes: {}, //object of booking types [type_id: {name: "Type name", dur: "duration in minutes"}]
            dayTimes: [], //array of times available for each day (by index, 0=sunday)
            prevComments: [], //array of all previous comments
            prevFiles: [], //array of all previously uploaded files
        };
    }

    handleChange = (inputName, newValue) => {
        let newState = {error: false};
        newState.fields = this.state.fields;
        newState.fields[inputName] = newValue;
        if( 'date' === inputName || 'insptype' === inputName ){
            newState.fields['time'] = '';
        }
        this.setState(newState);
    };
    handleDateChange = (date) => {
        if( !this.state.readOnly ){
            this.handleChange('date', this.formatDate(date));
        }
    };
    handleUpload = (file) => {
        if( file.id ){
            //add to save request
            let newState = {};
            newState.fields = this.state.fields;
            let copy = this.state.fields.files;
            //JSON string of {"filename": "file ID", ...}
            copy.push(file.id);
            newState.fields.files = copy;
            newState.filesLoading = false
            this.setState(newState);
        } //else error - already handled in FileUploader
    };
    handleFileSelected = () => {
        this.setState({filesLoading: true});
    };
    handleUploadsCleared = () => {
        if(this.logging) console.log('Uploads Cleared');
        let newState = {};
        newState.fields = this.state.fields;
        newState.fields.files = [];
        newState.filesLoading = false;
        this.setState(newState);
        //JSON string of {"filename": "file ID", ...}
    };
    prefillContact = (from) => {
        let name = this.state.fields.owner_fname + " " + this.state.fields.owner_lname;
        let phone = this.state.fields.owner_phone;
        if( 'tenant' === from ){
            name = this.state.fields.tenant_names;
            phone = this.state.fields.tenant_phone;
        } else if( 'agent' === from ){
            this.setState({contactLoading: true});
            this.prefillContactAgent();
            return;
        }

        let newState = {};
        newState.fields = this.state.fields;
        newState.fields.contact_name = name;
        newState.fields.contact_phone = phone;

        this.setState(newState);
    };
    prefillContactAgent = async () => {
        let response = null;
        try{
            response = await this.context.getProfile();
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }

        let data = response;

        if( typeof data == "object" && typeof data.code == "undefined" ){
            //console.log(data);
            let newState = {
                contactLoading: false,
                fields: this.state.fields
            };

            newState.fields.contact_name = data.name;
            newState.fields.contact_phone = data.billing_phone;

            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again.";
            if( typeof data == "object" &&  typeof data.code != "undefined" ){
                errorMsg += " [msg: "+data.code+"]";
            }
            this.setState({contactLoading: false, error: true, errorMsg});
        }
    };
    handleSubmit = (event) => {
        event.preventDefault();
        if(this.logging) console.log('submit form');
        if(this.logging) console.log( this.state.fields );

        if( this.state.filesLoading ){
            this.setState({
                error:true,
                errorMsg: "Please wait until the files have finished uploading",
            });
            return;
        }

        // validate then send to server
        let error = false;
        let errorMsg = '';
        let errorFld = '';
        let input = this.state.fields;
        let errorFields = [
            {fld: 'owner_fname', err: 'Please enter the Owner First Name'},
            {fld: 'owner_lname', err: 'Please enter the Owner Last Name'},
            {fld: 'address', err: 'Please enter the property Address'},
            {fld: 'city', err: 'Please enter the Suburb'},
            {fld: 'postcode', err: 'Please enter the Postcode'},
            {fld: 'date', err: 'Please select a Date'},
            {fld: 'time', err: 'Please select a Time'},
            {fld: 'insptype', err: 'Please select an Inspection Type'},
        ];
        errorFields.reverse();
        errorFields.forEach(function( field ){
            let key = field.fld;
            let msg = field.err;
            if( '' === input[key] ){
                error = true;
                errorMsg = msg;
                errorFld = key;
            }
        });

        // validate then send to server
        if( !error ){
            //send to server
            this.sendUpdate();
            this.setState({loading:true});
        } else {
            this.setState({error,errorMsg,errorFld});
        }
    };
    sendUpdate = async () => {
        let { fields, post_id } = this.state;
        let response = null;
        if(this.logging) console.log('Sending to server');
        if(this.logging) console.log(this.state);
        if(this.logging) console.log(fields);
        if(this.logging) console.log(post_id);
        try{
            response = await this.context.updateBooking( fields, post_id );
        } catch(err) {
            console.log(err);
            response = {
                code: 'server_error'
            };
        }
        if(this.logging) console.log('Server Reponse: ');
        if(this.logging) console.log( response );

        //check results, set loading to false, display errors or set loggedIn to true
        if( typeof response == "object" && typeof response.id != "undefined" && response.id ){
            // update profile fields with server-validated content
            this.updateBookingState(response, true);
        } else {
            let errorMsg = 'Error: failed to update booking.';
            let errorFld = '';
            if( typeof response == "object" && response.code && response.code.includes('invalid_date') ){
                errorFld = 'date';
                errorMsg = 'This date is unavailable. Please select another';
            } else if( typeof response == "object" && response.code && response.code.includes('invalid_time') ){
                errorFld = 'time';
                errorMsg = 'This time is unavailable. Please select another';
            } else if( typeof response == "object" && response.message ){
                errorMsg = response.message;
            }

            this.setState({loading:false, error:true, errorMsg, errorFld});
        }
    };
    getAvailability = async () => {
        if(this.logging) console.log('Fetching available bookings');
        let response = null;
        try{
            response = await this.context.getAvailableBookings();
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }

        if( typeof response == "object" && response.start ){
            let openDate = new Date( response.start );
            let closeDate = new Date( response.end );

            let dayTimes = (response.times || []);

            //set unavaliable days (ie. Saturday/Sunday)
            let unAvailableDays = [];
            for( let i=0; i<=6; i++ ){
                if( typeof response.times[i] == "undefined" ){
                    unAvailableDays.push(i);
                }
            }

            let inspectionTypes = (response.types || []);

            let unAvailableDates = (response.unav_dates || []);
            let unAvailableTimes = (response.unav_times || []);

            let newState = {error:false, openDate, closeDate, dayTimes, unAvailableDays, unAvailableDates, unAvailableTimes, inspectionTypes};

            newState.fields = this.state.fields;
            if( '' === this.state.fields.date ){
                let nextDate = openDate; //pre-select the first available date
                let err = 0;
                while( unAvailableDates.includes(this.formatDate(nextDate)) || unAvailableDays.includes(nextDate.getDay()) ){
                    nextDate.setDate(nextDate.getDate() + 1); //add a day
                    if( err++ >= 100 ) break;
                }
                newState.fields.date = this.formatDate(nextDate);
            } else if ( 0 !== this.state.post_id ) {
                //check whether booking should run in readOnly mode
                let bookingDate = new Date( this.state.fields.date );
                if( bookingDate < openDate ){
                    newState.readOnly = true;
                }
            }

            //set default inspection type
            if( '1' === this.state.fields.insptype ){
                newState.fields.insptype = Object.keys(inspectionTypes)[0];
            }

            this.setState(newState);
        } else {
            let errorMsg = "Failed to communicate with server. Please check your connection and try again";
            this.setState({error: true, errorMsg});
        }
    };
    getExistingBooking = async () => {
        let booking = this.state.post_id;

        let response = null;
        try{
            response = await this.context.getBooking( booking );
        } catch(err) {
            response = {
                code: 'server_error'
            };
        }

        if( typeof response == "object" && typeof response.id != "undefined" && response.id ){
            this.updateBookingState(response);
        } else {
            //display error
            let errorMsg = "ERROR: Failed to lookup booking.";
            this.setState({loading:false, error:true, errorMsg});
        }
    };
    updateBookingState = (response, updated) => {
        let newState = {
            loading:false,
            post_id: response.id,
            fields: this.state.fields
        };
        if( typeof updated != "undefined" && updated ){
            newState.updated = true;
        }
        //loop thorugh each field to set initial value
        for (var ind in this.state.fields) {
            if( typeof response[ind] != "undefined" ){
                newState.fields[ind] = response[ind];
            }
        }
        newState.fields.comment = ''; //don't pre-set the comment field (handled below)
        newState.fields.files = []; //don't pre-set the file upload field (handled below)

        //loop through fields with different meta key names
        let fieldMeta = [
            {fld: 'date', meta: 'inspectiondate'},
            {fld: 'time', meta: 'start'}
        ];
        fieldMeta.forEach(function( field ){
            let key = field.fld;
            let meta = field.meta;
            if( typeof response[meta] != "undefined" ){
                newState.fields[key] = response[meta];
            }
        });

        //make currently selected date available
        let unAvailableDates = this.state.unAvailableDates;
        if( unAvailableDates.includes( newState.fields['date'] ) ){
            unAvailableDates.splice( unAvailableDates.indexOf( newState['date'] ), 1 );
        }
        newState.unAvailableDates = unAvailableDates;

        //make currently selected time available
        let unAvailableTimes = this.state.unAvailableTimes;
        if( typeof unAvailableTimes[newState.fields['date']] != "undefined" ){
            let unTimes = unAvailableTimes[newState.fields['date']];
            if( unTimes.includes( newState.fields['time'] ) ){
                unTimes.splice( unTimes.indexOf( newState.fields['time'] ), 1 );
            }
            unAvailableTimes[newState.fields['date']] = unTimes;
            newState.unAvailableTimes = unAvailableTimes;
        }

        //display existing comments
        newState.prevComments = response.comment;

        //display existing files
        newState.prevFiles = response.files;

        //check whether booking should run in readOnly mode
        let bookingDate = new Date( newState.fields.date );
        if( bookingDate < this.state.openDate ){
            newState.readOnly = true;
        }

        this.setState(newState);
    };
    getTimeOptions = () => {
        let selDate = this.state.fields.date || '';
        if( '' === selDate ){
            return null;
        }

        let menuItems = [];

        let dt = new Date( selDate );
        let formattedDate = this.formatDate(dt);
        let unAvTimes = (this.state.unAvailableTimes[formattedDate] || []);

        let dayNum = dt.getDay();
        let avTimes = (this.state.dayTimes[dayNum] || []);

        //get the inspection duration & only enable bookings when enough time is available
        let inspDuration = 60; //(default) in minutes
        let inspType = (this.state.fields.insptype || '1'); //selected inspection type (id)
        let allTypes = this.state.inspectionTypes;
        if( typeof allTypes[inspType] != "undefined" ){
            inspDuration = allTypes[inspType]['dur'];
        }
        let loops = (inspDuration / 30); //number of 30 minute blocks to check through for availability
        loops = Math.floor(loops) - 1;

        //console.log('InspDuration: '+loops);

        let now = new Date();
        let key = 0;
        //console.log(unAvTimes);
        for (var val in avTimes) {
            let label = avTimes[val];
            let booked = false;
            if( unAvTimes.includes(val) ){
                booked = true;
            }
            //check that entire duration of booking is available (each 30 minute increment)
            let timeParts = val.split(':');
            now.setHours(timeParts[0]);
            now.setMinutes(timeParts[1]);
            //console.log(now.getHours() + ":" + now.getMinutes());
            for( let lp=1; lp<=loops; lp++ ){
                now.setMinutes(now.getMinutes() + 30);
                let timeHrs = now.getHours();
                if (timeHrs < 10) {
                    timeHrs = "0" + timeHrs;
                }
                let timeMins = now.getMinutes();
                if (timeMins < 10) {
                    timeMins = "0" + timeMins;
                }
                let timeStr = timeHrs + ":" + timeMins;
                //console.log(timeStr);
                if( unAvTimes.includes(timeStr) ){
                    //console.log(timeStr + " is unavailable");
                    booked = true;
                }
            }

            //console.log(label + ' Booked: '+booked);

            menuItems.push( (<MenuItem value={val} key={key++} disabled={booked}>{label}</MenuItem>) );
        }
        return menuItems;
    }
    getTypeOptions = () => {
        let menuItems = [];

        let types = this.state.inspectionTypes;

        let key = 0;
        for (var t_id in types) {
            if (types.hasOwnProperty(t_id)) {
                let atts = types[t_id];
                menuItems.push( (<MenuItem value={t_id} key={key++}>{atts.name}</MenuItem>) );
            }
        }
        return menuItems;
    };
    disableUnavailableDates = (date) => {
        // check whether this date has any time-slots left
        let formattedDate = this.formatDate(date);
        let unAvTimes = (this.state.unAvailableTimes[formattedDate] || []);
        let dayNum = date.getDay();
        let avTimes = (this.state.dayTimes[dayNum] || []);
        let bookedSpaces = 0;
        let totalSpaces = 0;
        for (var val in avTimes) {
            totalSpaces++
            if( unAvTimes.includes(val) ){
                bookedSpaces++;
            }
        }
        let isAvailable = true;
        if( 0 !== totalSpaces && bookedSpaces >= totalSpaces ){
            isAvailable = false;
        }

        //exclude weekends and unavaliable days
        return this.state.unAvailableDays.includes(date.getDay()) || this.state.unAvailableDates.includes(formattedDate) || !isAvailable;
    };
    formatDate = (d) => {
        return d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
    }
    getDate = (dt) =>{
        let d = new Date( '1970-01-01' );
        if( typeof dt == "string" && '' !== dt && null !== dt ){
            d = new Date( dt );
        }
        return d;
    }
    getError = (input) => {
        let error = false;
        if( this.state.error && (input === this.state.errorFld || '' === this.state.errorFld) ){
            error = true;
        }

        return error;
    };
    getSubtitle = (input, wrap) => {
        let desc = '';
        if( this.state.error && (input === this.state.errorFld) ){
            desc = 'Invalid input';
            if( '' !== this.state.errorMsg ){
                desc = this.state.errorMsg;
            }
        }

        if( wrap ){
            desc = ('' !== desc) ? (<FormHelperText error>{desc}</FormHelperText>) : '';
        }

        return desc;
    };
    resize = () => {
        let calOrientation = "portrait";
        if( window.innerWidth >= 700 ){
            calOrientation = "landscape";
        }
        if( this.state.calOrient !== calOrientation ){
            this.setState({calOrient: calOrientation});
        }
    };
    componentDidMount(){
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        //send AJAX request to server to find available dates & times
        this.getAvailability();

        if( 0 !== this.state.post_id && '0' !== this.state.post_id ){
            this.getExistingBooking();
        }

    }
    render() {
        const { classes } = this.props; //use this later
        return (
            <form onSubmit={this.handleSubmit}>
                <Snackbar
                  place="tc"
                  color="danger"
                  icon={AddAlert}
                  message={this.state.errorMsg}
                  open={(this.state.error && '' === this.state.errorFld)}
                  closeNotification={() => this.setState({ error: false })}
                  close
                />
                <Snackbar
                  place="tc"
                  color="success"
                  icon={AddAlert}
                  message="Your booking request was successfully lodged and will be confirmed shortly!"
                  open={(this.state.updated)}
                  closeNotification={() => this.setState({ updated: false })}
                  close
                />
                <Snackbar
                  place="bc"
                  color="warning"
                  icon={AddAlert}
                  message="You cannot make any changes to this booking as the booking date has either passed or is too soon to modify."
                  open={(this.state.readOnly)}
                />
                <Card>
                    <CardHeader color="primary">
                        <h4 className={classes.cardTitleWhite}>Book an Inspection</h4>
                        <p className={classes.cardCategoryWhite}>Fill out the below details to request an inspection</p>
                    </CardHeader>
                    <CardBody>
                        {/* Owner */}
                        <h3 className={classes.sectionTitle}>Owner Details</h3>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    label="Owner First Name"
                                    id="owner_fname"
                                    error={this.getError('owner_fname')}
                                    helperText={this.getSubtitle('owner_fname')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['owner_fname']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    label="Owner Last Name"
                                    id="owner_lname"
                                    error={this.getError('owner_lname')}
                                    helperText={this.getSubtitle('owner_lname')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['owner_lname']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        {/* Owner Contact */}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    label="Owner Phone"
                                    id="owner_phone"
                                    error={this.getError('owner_phone')}
                                    helperText={this.getSubtitle('owner_phone')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['owner_phone']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    label="Owner Email"
                                    id="owner_email"
                                    error={this.getError('owner_email')}
                                    helperText={this.getSubtitle('owner_email')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['owner_email']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {/* Address */}
                        <h3 className={classes.sectionTitle}>Property Information</h3>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    label="Address"
                                    id="address"
                                    error={this.getError('address')}
                                    helperText={this.getSubtitle('address')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['address']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    label="Address (line 2)"
                                    id="address2"
                                    error={this.getError('address2')}
                                    helperText={this.getSubtitle('address2')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['address2']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        {/* Address (row 2) */}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                                <TextField
                                    label="Suburb"
                                    id="city"
                                    error={this.getError('city')}
                                    helperText={this.getSubtitle('city')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['city']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <FormControl className={classes.selectControl}>
                                    <InputLabel
                                        error={this.getError('state')}
                                        htmlFor="state"
                                        className={classes.inputControlLabel}
                                    >
                                        State
                                    </InputLabel>
                                    <Select
                                        value={this.state.fields['state']}
                                        onChange={(event) => this.handleChange(event.target.name, event.target.value)}
                                        disabled={(this.state.openDate===this.state.closeDate || this.state.readOnly)}
                                        name="state"
                                        error={this.getError('state')}
                                    >
                                        <MenuItem value="ACT" key="0">ACT</MenuItem>
                                        <MenuItem value="NSW" key="1">NSW</MenuItem>
                                        <MenuItem value="QLD" key="2">QLD</MenuItem>
                                        <MenuItem value="SA" key="3">SA</MenuItem>
                                        <MenuItem value="TAS" key="4">TAS</MenuItem>
                                        <MenuItem value="VIC" key="5">VIC</MenuItem>
                                        <MenuItem value="WA" key="6">WA</MenuItem>
                                        <MenuItem value="NT" key="7">NT</MenuItem>
                                    </Select>
                                    {this.getSubtitle('state',true )}
                                </FormControl>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={4}>
                                <TextField
                                    label="Postcode"
                                    id="postcode"
                                    error={this.getError('postcode')}
                                    helperText={this.getSubtitle('postcode')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['postcode']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        {/* Block & Section */}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    label="Block Number"
                                    id="block"
                                    error={this.getError('block')}
                                    helperText={this.getSubtitle('block')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['block']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <TextField
                                    label="Section Number"
                                    id="section"
                                    error={this.getError('section')}
                                    helperText={this.getSubtitle('section')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['section']}
                                    fullWidth
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <h3 className={classes.sectionTitle}>Inspection</h3>
                        {/* Type Field */}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <FormControl className={classes.selectControl}>
                                    <InputLabel error={this.getError('insptype')} htmlFor="insptype" className={classes.inputControlLabel}>Type</InputLabel>
                                    <Select
                                        value={this.state.fields['insptype']}
                                        onChange={(event) => this.handleChange(event.target.name, event.target.value)}
                                        disabled={(Object.keys(this.state.inspectionTypes).length === 0 || this.state.readOnly)}
                                        name="insptype"
                                        error={this.getError('insptype')}
                                    >
                                        {this.getTypeOptions()}
                                    </Select>
                                    {this.getSubtitle('insptype',true )}
                                </FormControl>
                            </GridItem>
                        </GridContainer>
                        {/* Date field */}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.dateWrapper}>
                                    <InputLabel htmlFor="date" className={classes.datePicker}>Date</InputLabel>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            autoOk
                                            orientation={this.state.calOrient}
                                            variant="static"
                                            openTo="date"
                                            format="dd/MM/yyyy"
                                            id="date"
                                            minDate={this.state.openDate}
                                            maxDate={this.state.closeDate}
                                            shouldDisableDate={this.disableUnavailableDates}
                                            disabled={this.state.readOnly}
                                            value={this.getDate(this.state.fields['date'])}
                                            onChange={this.handleDateChange}
                                        />
                                    </MuiPickersUtilsProvider>
                                    {(this.state.openDate===this.state.closeDate) && <div className={classes.dateOverlay}><CircularProgress size={24} className={classes.dateLoader} /></div>}
                                </div>
                            </GridItem>
                        </GridContainer>
                        {/* Time field */}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <FormControl className={classes.selectControl}>
                                    <InputLabel error={this.getError('time')} htmlFor="time" className={classes.inputControlLabel}>Time</InputLabel>
                                    <Select
                                        value={this.state.fields['time']}
                                        onChange={(event) => this.handleChange(event.target.name, event.target.value)}
                                        disabled={(this.state.openDate===this.state.closeDate || this.state.readOnly)}
                                        name="time"
                                        error={this.getError('time')}
                                    >
                                        {this.getTimeOptions()}
                                    </Select>
                                    {this.getSubtitle('time',true )}
                                </FormControl>
                            </GridItem>
                        </GridContainer>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {/* Tenant field */}
                        <h3 className={classes.sectionTitle}>Tenant Information</h3>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <FormControl className={classes.toggleControl}>
                                    <FormControlLabel
                                        label="Property has a Tenant"
                                        className={classes.inputControlLabel}
                                        control={
                                            <Switch
                                                checked={('on' === this.state.fields['has_tenant'])}
                                                onChange={(event) => this.handleChange("has_tenant", (event.target.checked?'on':''))}
                                                disabled={(this.state.openDate===this.state.closeDate || this.state.readOnly)}
                                                value="has_tenant"
                                                color="primary"
                                            />
                                        }
                                    />

                                    <FormHelperText>{(this.state.fields['has_tenant']) ? "Enter tenant details" : "No tenant"}</FormHelperText>
                                </FormControl>
                            </GridItem>
                        </GridContainer>

                        {('on' === this.state.fields['has_tenant']) && (
                            <GridContainer className={classes.tenants}>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        label="Tenant Name"
                                        id="tenant_names"
                                        error={this.getError('tenant_names')}
                                        helperText={this.getSubtitle('tenant_names')}
                                        type="text"
                                        className={classes.textInput + " " + classes.tenantInput}
                                        value={this.state.fields['tenant_names']}
                                        fullWidth
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        InputProps={{
                                            readOnly: (this.state.loading || this.state.readOnly)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        label="Tenant Phone"
                                        id="tenant_phone"
                                        error={this.getError('tenant_phone')}
                                        helperText={this.getSubtitle('tenant_phone')}
                                        type="text"
                                        className={classes.textInput + " " + classes.tenantInput}
                                        value={this.state.fields['tenant_phone']}
                                        fullWidth
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        InputProps={{
                                            readOnly: (this.state.loading || this.state.readOnly)
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={4}>
                                    <TextField
                                        label="Tenant Email"
                                        id="tenant_email"
                                        error={this.getError('tenant_email')}
                                        helperText={this.getSubtitle('tenant_email')}
                                        type="email"
                                        className={classes.textInput + " " + classes.tenantInput}
                                        value={this.state.fields['tenant_email']}
                                        fullWidth
                                        onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                        InputProps={{
                                            readOnly: (this.state.loading || this.state.readOnly)
                                        }}
                                    />
                                </GridItem>
                            </GridContainer>
                        )}

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {/* Meeting field */}
                        <h3 className={classes.sectionTitle}>Meeting the Inspector</h3>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <FormControl className={classes.toggleControl}>
                                    <FormControlLabel
                                        label="Somebody will meet the Inspector"
                                        className={classes.inputControlLabel}
                                        control={
                                            <Switch
                                                checked={('on' === this.state.fields['meet_inspector'])}
                                                onChange={(event) => this.handleChange("meet_inspector", (event.target.checked?'on':''))}
                                                disabled={(this.state.openDate===this.state.closeDate || this.state.readOnly)}
                                                value="meet_inspector"
                                                color="primary"
                                            />
                                        }
                                    />

                                    <FormHelperText>{(this.state.fields['meet_inspector']) ? "Enter contact details" : "Please use the Comment field below to describe how the Inspector should gain access"}</FormHelperText>
                                </FormControl>
                            </GridItem>
                        </GridContainer>

                        {('on' === this.state.fields['meet_inspector']) && (
                            <>
                                <GridContainer className={classes.tenants}>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            label="Contact Name"
                                            id="contact_name"
                                            error={this.getError('contact_name')}
                                            helperText={this.getSubtitle('contact_name')}
                                            type="text"
                                            className={classes.textInput + " " + classes.tenantInput}
                                            value={this.state.fields['contact_name']}
                                            fullWidth
                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            InputProps={{
                                                readOnly: (this.state.loading || this.state.readOnly)
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <TextField
                                            label="Contact Phone"
                                            id="contact_phone"
                                            error={this.getError('contact_phone')}
                                            helperText={this.getSubtitle('contact_phone')}
                                            type="text"
                                            className={classes.textInput + " " + classes.tenantInput}
                                            value={this.state.fields['contact_phone']}
                                            fullWidth
                                            onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                            InputProps={{
                                                readOnly: (this.state.loading || this.state.readOnly)
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer className={classes.tenants}>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <div className={classes.buttonWrapper}>
                                            <Button
                                                className={classes.smallButton}
                                                fullWidth
                                                color="info"
                                                type="submit"
                                                disabled={(this.state.readOnly)}
                                                onClick={(e) => {e.preventDefault();this.prefillContact('owner')}}
                                            >Owner</Button>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <div className={classes.buttonWrapper}>
                                            <Button
                                                className={classes.smallButton}
                                                fullWidth
                                                color="info"
                                                type="submit"
                                                disabled={(this.state.readOnly)}
                                                onClick={(e) => {e.preventDefault();this.prefillContact('tenant')}}
                                            >Tenant</Button>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <div className={classes.buttonWrapper}>
                                            <Button
                                                className={classes.smallButton}
                                                fullWidth
                                                color="info"
                                                type="submit"
                                                disabled={(this.state.contactLoading || this.state.readOnly)}
                                                onClick={(e) => {e.preventDefault();this.prefillContact('agent')}}
                                            >Agent</Button>
                                            {this.state.contactLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </div>

                                    </GridItem>
                                </GridContainer>
                            </>
                        )}

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {/* Comments field - displays multiple comments in a table */}
                        <h3 className={classes.sectionTitle}>Additional Information</h3>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <Comments comments={this.state['prevComments']} />
                                <TextField
                                    label="Comment"
                                    id="comment"
                                    error={this.getError('comment')}
                                    helperText={this.getSubtitle('comment')}
                                    type="text"
                                    className={classes.textInput}
                                    value={this.state.fields['comment']}
                                    fullWidth
                                    multiline
                                    onChange={(event) => this.handleChange(event.target.id, event.target.value)}
                                    InputProps={{
                                        readOnly: (this.state.loading || this.state.readOnly)
                                    }}
                                />
                            </GridItem>
                        </GridContainer>

                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        {/* File upload field */}
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <h3 className={classes.sectionTitle}>Attached Files</h3>
                                { (0 !== this.state.prevFiles.length) && (
                                    <>
                                        <Attachments className={classes.attachmentsListing} files={this.state.prevFiles} />
                                    </>
                                )}
                                <FormControl className={classes.selectControl}>
                                    <FileUploader
                                        className={classes.textInput}
                                        onFileUploaded={this.handleUpload}
                                        onFileSelected={this.handleFileSelected}
                                        onCleared={this.handleUploadsCleared}
                                        clearUploads={this.state.loading}
                                    />
                                    {this.getSubtitle('files',true )}
                                </FormControl>
                            </GridItem>
                        </GridContainer>

                    </CardBody>
                </Card>
                <div className={classes.buttonWrapper}>
                    <Button
                        className={classes.button}
                        fullWidth
                        color="primary"
                        type="submit"
                        disabled={(this.state.loading || this.state.readOnly)}
                    >{(0===this.state.post_id)?"Request":"Update"} Inspection</Button>
                    {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
            </form>
        );
    }
}

BookInspection.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BookInspection);
